// modules
import Loaded from 'imagesloaded';

// magazine
class Magazine {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-magazine')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const cursor = document.querySelector('.a-cursor');

    const masks = section.querySelector('.c-masks');
    const mask = section.querySelectorAll('.c-mask');
    const spreads = section.querySelectorAll('.c-spread');

    const firstSpread = spreads[0];
    const lastSpread = spreads[spreads.length - 1];

    lastSpread.classList.add('is-last');
    firstSpread.classList.add('is-first', 'is-active');

    function hideHud() {

      const main = document.querySelector('.a-main');

      main.style.zIndex = '20';

    }

    hideHud();

    // init
    function initPages() {

      [...spreads].forEach((spread) => {

        const images = spread.querySelectorAll('.c-image');

        Loaded(images, function (instance) {

          [...images].forEach((image) => {

            let page = image.parentNode;
            let pageWidth = image.offsetWidth;

            let spread = page.parentNode;
            let spreadWidth = (pageWidth * 2) + 1;

            page.style.width = '' + pageWidth + 'px';
            masks.style.width = '' + spreadWidth + 'px';
            spread.style.width = '' + spreadWidth + 'px';

          });

        });

        const pagesRight = spread.querySelectorAll('.c-page:nth-of-type(2n+2)');

        [...pagesRight].forEach((pageRight) => {
          pageRight.classList.add('is-right')
        });

        const pagesLeft = spread.querySelectorAll('.c-page:nth-of-type(2n+1)');

        [...pagesLeft].forEach((pageLeft) => {
          pageLeft.classList.add('is-left')
        });

      });

    }

    initPages();

    // resize
    window.onresize = function () {
      initPages();
    };

    if (spreads.length <= 1) {
      masks.classList.add('is-single');
      return;
    };

    // next
    function nextPage() {

      const animateIn = new TimelineMax

      let nextSpread;
      let activeSpread = document.querySelector('.c-spreads .is-active');

      if (activeSpread.classList.contains('is-last')) {
        nextSpread = spreads[0];
      } else {
        nextSpread = activeSpread.nextElementSibling;
      }

      animateIn
        .set(mask, {
          opacity: 1,
          xPercent: 100
        }, 0)
        .staggerTo(mask, 1, {
          xPercent: 0,
          onComplete: out,
          ease: Cubic.easeOut
        }, -0.2, 0);

      function out() {

        const animateOut = new TimelineMax

        masks.style.pointerEvents = '';
        nextSpread.classList.add('is-active');
        activeSpread.classList.remove('is-active');

        animateOut
          .set(activeSpread, {
            opacity: 0
          }, 0)
          .set(nextSpread, {
            opacity: 1
          }, 0)
          .staggerTo(mask, 1, {
            xPercent: -100,
            ease: Cubic.easeOut
          }, 0.2, 0);

      }

    }

    // prev
    function previousPage() {

      const animateIn = new TimelineMax

      let previousSpread;
      let activeSpread = document.querySelector('.c-spreads .is-active');

      if (activeSpread.classList.contains('is-first')) {
        previousSpread = spreads[spreads.length - 1];
      } else {
        previousSpread = activeSpread.previousElementSibling;
      }

      animateIn
        .set(mask, {
          opacity: 1,
          xPercent: -100
        }, 0)
        .staggerTo(mask, 1, {
          xPercent: 0,
          onComplete: out,
          ease: Cubic.easeOut
        }, 0.2, 0);

      function out() {

        const animateOut = new TimelineMax

        masks.style.pointerEvents = '';
        previousSpread.classList.add('is-active');
        activeSpread.classList.remove('is-active');

        animateOut
          .set(activeSpread, {
            opacity: 0
          }, 0)
          .set(previousSpread, {
            opacity: 1
          }, 0)
          .staggerTo(mask, 1, {
            xPercent: 100,
            ease: Cubic.easeOut
          }, -0.2, 0);
      }

    }

    // bindings
    function initEvents() {

      const left = section.querySelector('.c-left');
      const right = section.querySelector('.c-right');
      const masks = section.querySelector('.c-masks');

      left.addEventListener('click', () => {

        masks.style.pointerEvents = 'none';

        previousPage();
      });

      left.addEventListener('mouseenter', () => {

        cursor.innerHTML = '';

        cursor.insertAdjacentHTML('beforeend', '<label>Previous</label>');

        const label = cursor.querySelector('label');

        const labelSplitter = new SplitText(label, {
          type: 'chars'
        }), labelChars = labelSplitter.chars;

        TweenMax.set(labelChars, {
          x: 50,
          opacity: 0
        });

        TweenMax.staggerTo(labelChars, 1, {
          x: 0,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.05);

      });

      right.addEventListener('click', () => {

        masks.style.pointerEvents = 'none';

        nextPage();
      });

      right.addEventListener('mouseenter', () => {

        cursor.innerHTML = '';

        cursor.insertAdjacentHTML('beforeend', '<label>Next</label>');

        const label = cursor.querySelector('label');

        const labelSplitter = new SplitText(label, {
          type: 'chars'
        }), labelChars = labelSplitter.chars;

        TweenMax.set(labelChars, {
          x: 50,
          opacity: 0
        });

        TweenMax.staggerTo(labelChars, 1, {
          x: 0,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.05);

      });

      masks.addEventListener('mouseleave', () => {
        cursor.innerHTML = '';
      });

    }

    initEvents();

  }

}

export default Magazine;
