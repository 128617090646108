// portfolio list
class PortfolioList {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-portfolio-list')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const nav = document.querySelector('.a-nav');
    const logo = document.querySelector('.a-logo');
    const burger = document.querySelector('.a-burger');
    const background = document.querySelector('.a-background');

    const categories = section.querySelectorAll('.c-category');
    const headings = section.querySelectorAll('.c-heading');
    const imageLarge = section.querySelector('.c-image-l');
    const imageSmall = section.querySelector('.c-image-s');
    const chapter = section.querySelector('.c-chapter');
    const button = section.querySelector('.f-button');

    [...categories].forEach((category) => {

      // mouse enter
      category.addEventListener('mouseenter', () => {

        nav.classList.add('is-white');
        logo.classList.add('is-white');
        burger.classList.add('is-white');

        let color = category.dataset.color;
        let heading = category.querySelector('.c-heading');

        let srcLage = category.querySelectorAll('img')[0].src;
        let srcSmall = category.querySelectorAll('img')[1].src;

        imageLarge.querySelector('.c-inside').style.backgroundImage = 'url(' + srcLage + ')';
        imageSmall.querySelector('.c-inside').style.backgroundImage = 'url(' + srcSmall + ')';

        // color
        const Tween = new TimelineMax();

        Tween
          .to(background, 0.75, {
            backgroundColor: color
          }, 0)
          .to(categories, 0.75, {
            color: "#FFFFFF"
          }, 0)
          .to(chapter, 0.75, {
            color: "#FFFFFF"
          }, 0)
          .to(button, 0.75, {
            opacity: 0
          }, 0)
          .to(headings, 0.1, {
            opacity: 0.2
          }, 0)
          .to(heading, 0.1, {
            opacity: 0.99
          }, 0)
          .to(imageLarge, 0.1, {
            opacity: 0.99
          }, 0)
          .to(imageSmall, 0.1, {
            opacity: 0.99
          }, 0);

      });

      // mouse leave
      category.addEventListener('mouseleave', () => {

        nav.classList.remove('is-white');
        logo.classList.remove('is-white');
        burger.classList.remove('is-white');

        const Tween = new TimelineMax();

        Tween
          .to(background, 0.75, {
            backgroundColor: '#FFFFFF'
          }, 0)
          .to(categories, 0.75, {
            color: "#000000"
          }, 0)
          .to(chapter, 0.75, {
            color: "#000000"
          }, 0)
          .to(button, 0.75, {
            opacity: 0.99
          }, 0)
          .to(headings, 0.1, {
            opacity: 0.99
          }, 0)
          .to(imageLarge, 0.1, {
            opacity: 0
          }, 0)
          .to(imageSmall, 0.1, {
            opacity: 0
          }, 0);

      });

    }, false);

    let x = 0,
      y = 0;

    // mouse move
    section.addEventListener('mousemove', (e) => {

      y = e.clientY;

      const srcLarge = imageLarge.querySelector('.c-inside');
      const srcSmall = imageSmall.querySelector('.c-inside');

      const tl = new TimelineMax();

      tl
        .to(imageLarge, 0.5, {
          y: y / 2.5
        }, 0)
        .to(imageSmall, 0.5, {
          y: y / 5
        }, 0)
        .to(srcLarge, 0.5, {
          y: y / 15
        }, 0)
        .to(srcSmall, 0.5, {
          y: y / 30
        }, 0);

    });

  }

}

export default PortfolioList;
