// videos slider
class VideosSlider {
    constructor() {

        this.dom = {
            section: document.querySelector('.s-videos-slider')
        };

        if (this.dom.section) this.initSlider();
    }

    initSlider() {

        const slider = this.dom.section;

        const texts = slider.querySelectorAll('.c-text .c-slide');
        const videos = slider.querySelectorAll('.c-video .c-slide');
        const bullets = slider.querySelectorAll('.c-bullets .c-bullet');

        const firstText = texts[0];
        const lastText = texts[texts.length - 1];

        let activeText = texts[0];
        let activeVideo = videos[0];
        let activeBullet = bullets[0];

        lastText.classList.add('is-last');
        firstText.classList.add('is-first');

        activeText.classList.add('is-active');
        activeVideo.classList.add('is-active');
        activeBullet.classList.add('is-active');

        this.sliderBindings(slider);

    }

    sliderBindings(slider) {

        const nav = slider.querySelector('.c-nav');
        const next = slider.querySelector('.c-next');
        const previous = slider.querySelector('.c-prev');

        const texts = slider.querySelectorAll('.c-text .c-slide');
        const videos = slider.querySelectorAll('.c-video .c-slide');
        const staggers = slider.querySelectorAll('.c-text .c-slide *');
        const bullets = slider.querySelectorAll('.c-bullets .c-bullet');

        const background = document.querySelector('.a-background');

        sliderSet();

        // next
        next.addEventListener('click', () => {

            nav.style.pointerEvents = 'none';

            let activeText = slider.querySelector('.c-text .is-active');
            let activeVideo = slider.querySelector('.c-video .is-active');
            let activeBullet = slider.querySelector('.c-bullets .is-active');

            let activeVideoSrc = activeVideo.querySelector('.c-src');
            let activeVideoMask = activeVideo.querySelector('.c-mask');

            activeVideoSrc.pause();

            let activeStagger = activeText.querySelectorAll('*');

            let nextText;
            let nextVideo;
            let nextBullet;
            let nextStagger;

            if (activeText.classList.contains('is-last')) {
                nextText = texts[0];
                nextVideo = videos[0];
                nextBullet = bullets[0];
                nextStagger = nextText.querySelectorAll('*');
            } else {
                nextText = activeText.nextElementSibling;
                nextVideo = activeVideo.nextElementSibling;
                nextBullet = activeBullet.nextElementSibling;
                nextStagger = nextText.querySelectorAll('*');
            }

            let nextVideoSrc = nextVideo.querySelector('.c-src');
            let nextVideoMask = nextVideo.querySelector('.c-mask');

            let activeColor = nextVideo.querySelector('.c-color').dataset.color;

            const tl = new TimelineMax();

            tl
                .set(videos, {
                    zIndex: 1
                })
                .set(nextVideo, {
                    zIndex: 2
                })
                .to(background, 0.75, {
                    delay: 0.75,
                    backgroundColor: activeColor
                }, 0)
                .to(nextVideoSrc, 1.5, {
                    scale: 1,
                    xPercent: 0,
                    ease: Power3.easeInOut
                }, 0)
                .to(nextVideoMask, 1.5, {
                    xPercent: 0,
                    ease: Power3.easeInOut
                }, 0)
                .staggerTo(activeStagger, 0.75, {
                    y: -50,
                    opacity: 0,
                    ease: Power3.easeIn
                }, 0.05, 0)
                .staggerTo(nextStagger, 0.75, {
                    y: 0,
                    opacity: 1,
                    ease: Power3.easeOut,
                    onComplete: sliderSet
                }, 0.05, 0.75);

            nextText.classList.add('is-active');
            nextVideo.classList.add('is-active');
            nextBullet.classList.add('is-active');

            activeText.classList.remove('is-active');
            activeVideo.classList.remove('is-active');
            activeBullet.classList.remove('is-active');

        });

        // previous
        previous.addEventListener('click', () => {

            nav.style.pointerEvents = 'none';

            let activeText = slider.querySelector('.c-text .is-active');
            let activeVideo = slider.querySelector('.c-video .is-active');
            let activeBullet = slider.querySelector('.c-bullets .is-active');

            let activeVideoSrc = activeVideo.querySelector('.c-src');
            let activeVideoMask = activeVideo.querySelector('.c-mask');

            activeVideoSrc.pause();

            let activeStagger = activeText.querySelectorAll('*');

            let previousText;
            let previousVideo;
            let previousBullet;
            let previousStagger;

            if (activeText.classList.contains('is-first')) {
                previousText = texts[texts.length - 1];
                previousVideo = videos[videos.length - 1];
                previousBullet = bullets[bullets.length - 1];
                previousStagger = previousText.querySelectorAll('*');
            } else {
                previousText = activeText.previousElementSibling;
                previousVideo = activeVideo.previousElementSibling;
                previousBullet = activeBullet.previousElementSibling;
                previousStagger = previousText.querySelectorAll('*');
            }

            let previousVideoSrc = previousVideo.querySelector('.c-src');
            let previousVideoMask = previousVideo.querySelector('.c-mask');

            let activeColor = previousVideo.querySelector('.c-color').dataset.color;

            const tl = new TimelineMax();

            tl
                .set(videos, {
                    zIndex: 1
                })
                .set(previousVideo, {
                    zIndex: 2
                })
                .to(background, 0.75, {
                    backgroundColor: activeColor
                }, 0)
                .to(previousVideoSrc, 1.5, {
                    scale: 1,
                    xPercent: 0,
                    ease: Power3.easeInOut
                }, 0)
                .to(previousVideoMask, 1.5, {
                    xPercent: 0,
                    ease: Power3.easeInOut
                }, 0)
                .staggerTo(activeStagger, 1.5, {
                    y: -50,
                    opacity: 0,
                    ease: Power3.easeInOut
                }, 0.1, 0)
                .staggerTo(previousStagger, 1.5, {
                    y: 0,
                    opacity: 1,
                    ease: Power3.easeInOut,
                    onComplete: sliderSet
                }, 0.1, 1);

            previousText.classList.add('is-active');
            previousVideo.classList.add('is-active');
            previousBullet.classList.add('is-active');

            activeText.classList.remove('is-active');
            activeVideo.classList.remove('is-active');
            activeBullet.classList.remove('is-active');

        });

        // set
        function sliderSet() {

            nav.style.pointerEvents = '';

            let videoSrc = slider.querySelectorAll('.c-src');
            let videoMask = slider.querySelectorAll('.c-mask');

            let activeVideo = slider.querySelector('.c-video .is-active');
            let activeVideoSrc = activeVideo.querySelector('.c-src');
            let activeVideoMask = activeVideo.querySelector('.c-mask');

            activeVideoSrc.play();

            let activeText = slider.querySelector('.c-text .is-active');
            let activeStagger = activeText.querySelectorAll('*');

            const tl = new TimelineMax();

            tl
                .set(videos, {
                    zIndex: 1
                }, 0)
                .set(videoSrc, {
                    scale: 1.5,
                    xPercent: -80
                }, 0)
                .set(videoMask, {
                    xPercent: 100
                }, 0)
                .set(activeVideoMask, {
                    xPercent: 0
                }, 0)
                .set(activeVideoSrc, {
                    scale: 1,
                    xPercent: 0
                }, 0)
                .set(staggers, {
                    y: 50,
                    opacity: 0
                }, 0)
                .set(activeStagger, {
                    y: 0,
                    opacity: 1
                }, 0);

        }
    }

}

export default VideosSlider;
