// modules
import Loaded from 'imagesloaded';
import Highway from '@dogstudio/highway';

// loader
const loader = document.querySelector('.a-loader');
const mask = loader.querySelector('.c-mask');

// transition
class NextTransition extends Highway.Transition {

  in({
    from,
    to,
    trigger,
    done
  }) {

    from.remove();

    done();

    // tween in
    const article = document.querySelector('.a-container');

    Loaded(article, function (instance) {

      loader.style.pointerEvents = 'none';

      TweenMax.set(article, {
        y: 150
      });

      TweenMax.to(article, 1, {
        y: 0,
        ease: Power3.easeInOut
      });

      TweenMax.to(mask, 1, {
        yPercent: 80,
        ease: Power3.easeInOut
      });

      TweenMax.fromTo(loader, 1, {
        yPercent: 0
      }, {
        yPercent: -100,
        ease: Power3.easeInOut
      });

    });

  }

  out({
    from,
    trigger,
    done
  }) {

    // tween out
    const article = document.querySelector('.a-container');

    let color = trigger.dataset.bg;

    loader.style.backgroundColor = color;
    loader.style.pointerEvents = 'all';

    TweenMax.set(loader, {
      opacity: 1
    });

    TweenMax.to(article, 1, {
      y: -150,
      ease: Power3.easeInOut
    });

    TweenMax.fromTo(mask, 1, {
      yPercent: -80
    }, {
      yPercent: 0,
      ease: Power3.easeInOut
    });

    TweenMax.fromTo(loader, 1, {
      yPercent: 100
    }, {
      yPercent: 0,
      ease: Power3.easeInOut,
      onComplete: () => {
        done();
      }
    });
  }

}

export default NextTransition;
