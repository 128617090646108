// testimonial
class TestimonialSingle {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-testimonial-single')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const main = document.querySelector('.a-main');

    main.style.zIndex = "20";

  }

}

export default TestimonialSingle;
