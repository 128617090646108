// modules
import Sniffer from 'sniffer';
import SmoothScroll from '../scroll/scroll';

// category
class CategoryNext {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-category-next')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const windowHeight = window.innerHeight;

    // transition
    section.querySelector('a').setAttribute('data-transition', 'header');

    let raf;

    // init
    function initScroll() {

      raf = undefined;

      const src = section.querySelector('.c-src');

      let scrollY;

      if (!Sniffer.isPhone) {
        scrollY = SmoothScroll.vars.current;
      }

      let elementOffset = section.getBoundingClientRect().top + scrollY;
      let elementHeight = section.clientHeight;

      let distance = (scrollY + windowHeight) - elementOffset;
      let percentage = distance / ((windowHeight + elementHeight) / 100);

      if (percentage < 100) {

        TweenMax.to(src, 0.2, {
          yPercent: percentage / 4,
          scale: 1.2 - (percentage / 500)
        });

      }

      start();

      return percentage;

    }

    // start
    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(initScroll);
      }
    }

    start();

    // stop
    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    // disable
    const links = document.getElementsByTagName('a');

    [...links].forEach((link) => {
      link.addEventListener("click", function (e) {
        stop();
      });
    }, false);

  }

}

export default CategoryNext;
