// modules
import Highway from '@dogstudio/highway';
import SplitText from 'gsap/SplitText';

// components
import SmoothScroll from '../../components/scroll/scroll';

// Fade
class BookTransition extends Highway.Transition {

  in({
    from,
    to,
    trigger,
    done
  }) {

    const placeholder = document.querySelector('.s-overlay-placeholder');

    from.remove();

    done();

    setTimeout(function () {

      placeholder.remove();

    }, 500);

  }

  out({
    from,
    trigger,
    done
  }) {

    SmoothScroll.off();

    const scrollY = SmoothScroll.vars.current;

    const active = from.querySelector('.is-active');
    const overlay = document.querySelector('.a-overlay');
    const hidden = document.querySelectorAll('.c-book:not(.is-active)');

    const src = active.querySelector('.c-img');
    const image = active.querySelector('.c-image');
    const button = active.querySelector('.f-button');
    const heading = active.querySelector('.f-heading-xl');
    const paragraph = active.querySelector('.f-paragraph');

    const splitter = new SplitText(heading, {
      type: 'lines'
    }),
      lines = splitter.lines;

    const imageWidth = image.offsetWidth;
    const windowWidth = window.innerWidth;

    const imageTop = getOffset(image).top;
    const imageLeft = getOffset(image).left;

    // offset
    function getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left,
        top: rect.top + scrollY
      };
    }

    // position
    image.style.left = '0';
    image.style.right = '0';

    // tween
    TweenMax.set(image, {
      x: imageLeft,
      width: imageWidth
    });

    TweenMax.staggerTo(lines, 1, {
      y: -50,
      opacity: 0,
      ease: Power3.easeInOut
    }, 0.1, createPlaceholder);

    TweenMax.to(paragraph, 1, {
      y: -50,
      opacity: 0,
      delay: 0.4,
      ease: Power3.easeInOut
    });

    TweenMax.to(button, 1, {
      y: -50,
      opacity: 0,
      delay: 0.5,
      ease: Power3.easeInOut
    });

    TweenMax.to(hidden, 1, {
      opacity: 0,
      ease: Power3.easeInOut
    });

    TweenMax.to(image, 1, {
      ease: Power3.easeInOut,
      width: windowWidth * 0.4,
      y: (scrollY - imageTop) + 150,
      x: (windowWidth / 2) - ((windowWidth * 0.4) / 2),
    });

    TweenMax.to(src, 1, {
      rotation: 0,
      yPercent: 0,
      ease: Power3.easeInOut
    });

    // placeholder
    function createPlaceholder() {

      overlay.insertAdjacentHTML('beforeend', '<div class="s-overlay-placeholder"></div>');

      const placeholder = document.querySelector('.s-overlay-placeholder');

      const placeholderImg = placeholder.appendChild(src.cloneNode(true));

      TweenMax.set(placeholderImg, {
        y: 150,
        width: windowWidth * 0.4,
        x: (windowWidth / 2) - ((windowWidth * 0.4) / 2)
      });

      SmoothScroll.on();

      SmoothScroll.vars.current = SmoothScroll.vars.target = 0;

      done();

    }
  }
}

export default BookTransition;
