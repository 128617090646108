// core values
class HeroAbout {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-hero-about')
    };

    if (this.dom.section) this.bindings();
  }

  init() {

    const section = this.dom.section;

    const room = section.querySelector('.c-room');
    const image = section.querySelector('.c-image');
    const plants = section.querySelector('.c-plants');
    const couple = section.querySelector('.c-couple');
    const background = section.querySelector('.c-background');

    image.addEventListener("mousemove", function (e) {

      const x = (e.clientX / window.innerWidth) - 0.5;
      const y = (e.clientY / window.innerHeight) - 0.5;
      const z = (e.clientY / window.innerHeight);

      const tl = new TimelineMax();

      tl
        .to(plants, 1, {
          x: 70 * x,
          scaleX: 1 + (z / 4),
          scaleY: 1 + (z / 4),
          force3D: true
        }, 0)
        .to(couple, 1, {
          x: 30 * x,
          force3D: true,
          scaleX: 1 + (z / 10),
          scaleY: 1 + (z / 10)
        }, 0)
        .to(room, 1, {
          x: 20 * x,
          scaleX: 1 + (z / 18),
          scaleY: 1 + (z / 18),
          force3D: true
        }, 0)
        .to(background, 1, {
          x: 10 * x,
          scaleX: 1 + (z / 50),
          scaleY: 1 + (z / 50),
          force3D: true
        }, 0);
    });

  }

  bindings() {

    this.init();

  }

}

export default HeroAbout;
