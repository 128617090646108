// modules
import Sniffer from 'sniffer';
import Loaded from 'imagesloaded';
import SmoothScroll from '../scroll/scroll';

// journal
class JournalArchive {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-journal-archive')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const windowHeight = window.innerHeight;

    const images = section.querySelectorAll('.c-src');

    // images
    Loaded(images, function (instance) {

      [...images].forEach((image) => {

        const parentLink = image.parentNode;
        const parentImage = parentLink.parentNode;
        const parentPost = parentImage.parentNode;

        if (image.naturalHeight > image.naturalWidth) {
          parentPost.classList.add('is-portrait')
        } else {
          parentPost.classList.add('is-landscape')
        }

      });

      const posts = section.querySelectorAll('.c-post:nth-of-type(2n+2)');

      [...posts].forEach((post) => {

        post.classList.add("is-left")

      });

      SmoothScroll.resize();

    });

    // scroll
    let raf;

    function scroll() {

      raf = undefined;

      let scrollY;

      if (!Sniffer.isPhone) {
        scrollY = SmoothScroll.vars.current;
      }

      if (Sniffer.isPhone) {

        const body = document.documentElement;

        scrollY = (window.pageYOffset || body.scrollY) - (body.clientTop || 0);
      }

      [...images].forEach((image) => {

        let offset = image.getBoundingClientRect().top + scrollY;
        let height = image.clientHeight;

        let distance = (scrollY + windowHeight) - offset;
        let percentage = distance / ((windowHeight + height) / 100);

        if (percentage < 100) {

          TweenMax.to(image, 0.2, {
            yPercent: percentage / 4
          });

        }

        return percentage;

      });

      start();
    }

    // start
    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(scroll);
      }
    }

    start();

    // stop
    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    // disable
    const links = document.getElementsByTagName('a');

    [...links].forEach((link) => {
      link.addEventListener('click', function () {
        stop();
      });
    });

  }

}

export default JournalArchive;
