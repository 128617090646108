// core values
class Cookies {

  constructor() {

    this.dom = {
      section: document.querySelector('.a-cookies')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    let button = section.querySelector('.f-button');

    button.addEventListener('click', () => {

      TweenMax.to(section, 1, {
        opacity: 0,
        onComplete: destroy
      });

    });

    function destroy() {
      section.outerHTML = "";
    }

  }

}

export default Cookies;
