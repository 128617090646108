// header image
class HeaderImage {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-header-image')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const back = section.querySelector('.c-back');

    TweenMax.set(back, {
      opacity: 0
    });

    TweenMax.to(back, 1, {
      delay: 1,
      opacity: 1,
    });

  }

}

export default HeaderImage;
