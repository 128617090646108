// viewport
import {
    isInViewport
} from './is-in-viewport';

// background color
const BackgroundColor = function() {

    const color = document.querySelectorAll('.c-color');
    const background = document.querySelector('.a-background');

    [...color].forEach((section) => {

        let bgColor = section.dataset.color

        if (isInViewport(section)) {

            TweenMax.to(background, 0.75, {
                backgroundColor: bgColor
            });

            return;

        }

    });

}

export default BackgroundColor;
