// modules
import Sniffer from 'sniffer';
import SmoothScroll from '../scroll/scroll';

// badge
class Badge {
  constructor() {

    this.dom = {
      section: document.querySelector('.c-badge')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const badge = this.dom.section;

    const logo = badge.querySelectorAll('.c-logo');
    const circle = badge.querySelectorAll('.c-circle');

    // up
    function scrollUp() {

      TweenMax.killTweensOf(logo);
      TweenMax.killTweensOf(circle);

      let logoUp = TweenMax.to(logo, 15, {
        repeat: -1,
        rotation: '-=360',
        ease: Linear.easeNone
      });

      let logoDown = TweenMax.to(circle, 10, {
        repeat: -1,
        rotation: '-=360',
        ease: Linear.easeNone
      });

    }

    function scrollDown() {

      TweenMax.killTweensOf(logo);
      TweenMax.killTweensOf(circle);

      TweenMax.to(logo, 15, {
        repeat: -1,
        rotation: '+=360',
        ease: Linear.easeNone
      });

      TweenMax.to(circle, 10, {
        repeat: -1,
        rotation: '+=360',
        ease: Linear.easeNone
      });

    }

    // scroll
    function scroll() {

      let raf;

      let scrollTop = 0;

      // init
      function rafScroll() {

        raf = undefined;

        let scrollOffset = window.pageYOffset || SmoothScroll.vars.current;

        if (scrollOffset > scrollTop) {

          if (!badge.classList.contains('is-down')) {

            badge.classList.add('is-down');
            badge.classList.remove('is-up');

            scrollDown();

          }

        } else {

          if (!badge.classList.contains('is-up')) {

            badge.classList.add('is-up');
            badge.classList.remove('is-down');

            scrollUp();

          }

        }

        scrollTop = scrollOffset <= 0 ? 0 : scrollOffset;

        start();
      }

      // start
      function start() {
        if (!raf) {
          raf = window.requestAnimationFrame(rafScroll);
        }
      }

      start();

      // stop
      function stop() {
        if (raf) {
          window.cancelAnimationFrame(raf);
          raf = undefined;
        }
      }

      // disable
      const links = document.getElementsByTagName('a');

      [...links].forEach((link) => {
        link.addEventListener('click', function () {
          stop();
        });
      }, false);

    }

    if (!Sniffer.isPhone) {
      scroll();
    }

    if (Sniffer.isPhone) {
      scrollDown();
    }

  }

}

export default Badge;
