// modules
import Loaded from 'imagesloaded';
import Draggable from 'gsap/umd/Draggable';
import SmoothScroll from '../scroll/scroll';
// import ThrowPropsPlugin from 'gsap';

// carousel
class Carousel {
  constructor() {

    this.dom = {
      section: document.querySelectorAll('.s-carousel')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const carousels = document.querySelectorAll('.s-carousel');

    if (!carousels.length) return;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // scroll
    let raf;

    [...carousels].forEach((carousel) => {

      if (carousel.querySelector('.c-letter')) {

        initSvg(carousel);
        initScroll(carousel);
        start(carousel);

      }

      initImages(carousel);
      initSlider(carousel);
      // initDragger(carousel);

    });

    // images
    function initImages(carousel) {

      const images = carousel.querySelectorAll('.c-src');
      const dragger = carousel.querySelector('.c-dragger');
      const container = carousel.querySelector('.c-container');

      Loaded(images, function (instance) {

        [...images].forEach((image) => {

          const parentImage = image.parentNode;
          const parentColumn = parentImage.parentNode;

          if (image.naturalHeight > image.naturalWidth) {
            parentColumn.classList.add('is-portrait')
          } else {
            parentColumn.classList.add('is-landscape')
          }

        });

        const portraits = carousel.querySelectorAll('.is-portrait:nth-of-type(2n+1)');

        [...portraits].forEach((portrait) => {

          portrait.classList.add('is-large')

        });

        const landscapes = carousel.querySelectorAll('.is-landscape:nth-of-type(2n+1)');

        [...landscapes].forEach((landscape) => {

          landscape.classList.add('is-large')

        });

        let draggerHeight = container.clientHeight;

        dragger.style.height = '' + draggerHeight + 'px'

      });

    }

    // slider
    function initSlider(carousel) {

      const next = carousel.querySelector('.c-next');
      const prev = carousel.querySelector('.c-prev');
      const container = carousel.querySelector('.c-container');

      let x = 0;

      prev.addEventListener('click', () => {
        if (-x <= 0) {
          x = 0
        } else {
          x += 300;
        }

        TweenMax.to(container, 0.5, {
          x,
          ease: Power3.easeOut
        });
      });

      next.addEventListener('click', () => {
        if (-x >= (container.clientWidth - window.innerWidth)) {
          x = -(container.clientWidth - window.innerWidth);
        } else {
          x -= 300;
        }

        TweenMax.to(container, 0.5, {
          x,
          ease: Power3.easeOut
        });
      });
    }

    // dragger (REMOVED)
    function initDragger(carousel) {

      const dragger = carousel.querySelector('.c-dragger');
      const images = carousel.querySelectorAll('.c-image');
      const container = carousel.querySelector('.c-container');

      Loaded(images, function (instance) {

        let draggerWidth = container.clientWidth;

        function scaleIn() {
          TweenMax.to(images, 0.5, {
            scale: 0.95,
            ease: Power3.easeOut
          });
        }

        function scaleOut() {
          TweenMax.to(images, 0.5, {
            scale: 1,
            ease: Power3.easeOut
          });
        }

        function getDuration(distance, pixelsPerSecond) {
          var duration = distance / pixelsPerSecond;
          return duration;
        }

        TweenMax.to(container, getDuration(draggerWidth, 50), {
          ease: Linear.easeNone,
          x: -(draggerWidth - windowWidth)
        });

        Draggable.create(container, {
          type: 'x',
          cursor: 'grab',
          bounds: dragger,
          throwProps: true,
          zIndexBoost: false,
          edgeResistance: 0.65,
          onDrag: function () {

            scaleIn();

            TweenLite.set(container, {
              cursor: "grabbing"
            });

          },
          onRelease: function () {

            scaleOut();

          }
        });

      });

    }

    // svg
    function initSvg(carousel) {

      let ratio;

      let svg = carousel.querySelector('.c-svg');
      let letter = carousel.querySelector('.c-letter');
      let object = carousel.querySelector('.c-object');

      if (letter) {

        let width = letter.offsetWidth
        let height = letter.offsetHeight;

        if (height > width) {
          ratio = height;
        } else {
          ratio = width;
        }

        svg.setAttribute('viewBox', '0 0 ' + ratio + ' ' + ratio + '');
        svg.setAttribute('height', '' + ratio + 'px');
        svg.setAttribute('width', '' + ratio + 'px');

        // object.setAttribute('height', '' + ratio + 'px');
        object.setAttribute('width', '' + ratio + 'px');

        letter.style.width = '' + ratio + 'px';
        letter.style.height = '' + ratio + 'px';

      }

    }

    // resize
    window.addEventListener('resize', handleResize);

    function handleResize() {

      [...carousels].forEach((carousel) => {

        initSvg(carousel);

        const dragger = carousel.querySelector('.c-dragger');
        const container = carousel.querySelector('.c-container');

        let draggerHeight = container.clientHeight;

        dragger.style.height = '' + draggerHeight + 'px'

      });

    }

    // init
    function initScroll(carousel) {

      raf = undefined;

      [...carousels].forEach((carousel) => {

        const letter = carousel.querySelector('text');

        let scrollY;

        scrollY = SmoothScroll.vars.current;

        let elementOffsetTop = carousel.getBoundingClientRect().top + scrollY;
        let elementHeight = carousel.clientHeight;

        let distance = (scrollY + windowHeight) - elementOffsetTop;
        let percentage = distance / ((windowHeight + elementHeight) / 100);

        if (percentage < 100) {

          TweenMax.to(letter, 0.2, {
            delay: 0.1,
            yPercent: percentage / 8
          });

        }

        return percentage;

      });

      start(carousel);
    }

    // start
    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(initScroll);
      }
    }

    // stop
    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    // disable
    const links = document.getElementsByTagName('a');

    [...links].forEach((link) => {
      link.addEventListener('click', (e) => {

        window.removeEventListener('resize', handleResize, false)

        stop();

      });
    }, false);

  }

}

export default Carousel;
