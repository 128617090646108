// components
import SmoothScroll from '../scroll/scroll';

// menu
const Intro = function () {

  SmoothScroll.off();

  const windowHeight = window.innerHeight;

  const section = document.querySelector('.a-intro');
  const container = document.querySelector('.a-container');

  const logo = section.querySelector('.c-logo');
  const mask = section.querySelector('.c-mask');
  const image = section.querySelector('.c-image');
  const shape = section.querySelector('.c-shape');
  const line = section.querySelectorAll('.c-line');
  const figure = section.querySelector('.c-figure');
  const heading = section.querySelectorAll('.f-heading-xl');
  const background = section.querySelector('.c-background');

  const tl = new TimelineLite();

  tl
    .set(container, {
      y: 150
    })
    .set(shape, {
      opacity: 1,
      yPercent: 100
    })
    .set(mask, {
      opacity: 1,
      yPercent: 101
    })
    .set(image, {
      opacity: 0.99,
      yPercent: -80
    })
    .set(heading, {
      yPercent: 100,
      onComplete: animateIn
    });

  function animateIn() {

    const tl = new TimelineLite();

    tl
      .to(logo, 1, {
        delay: 0.5,
        opacity: 0.99
      }, 0)
      .to(shape, 1.5, {
        yPercent: 0,
        ease: Power3.easeOut
      }, 1.5)
      .to(mask, 1.5, {
        yPercent: 0,
        ease: Power3.easeOut
      }, 2)
      .to(image, 1.5, {
        yPercent: 0,
        ease: Power3.easeOut,
        onComplete: animateOut
      }, 2)
      .staggerTo(heading, 1.5, {
        yPercent: -100,
        ease: Power3.easeOut
      }, 0.2, 2);

  }

  function animateOut() {

    const tl = new TimelineLite();

    tl
      .set(logo, {
        opacity: 0,
      })
      .staggerTo(line, 2, {
        y: -windowHeight,
        ease: Power3.easeInOut
      }, 0.1, 0.1)
      .to(figure, 2, {
        y: -windowHeight,
        ease: Power3.easeInOut
      }, 0.3)
      .to(container, 2, {
        y: 0,
        ease: Power3.easeInOut
      }, 0.4)
      .to(background, 2, {
        yPercent: -100,
        ease: Power3.easeInOut,
        onComplete: destroy
      }, 0.4);

  }

  function checkHud() {

    const child = document.querySelector('.a-container').firstElementChild;

    if (child.hasAttribute('data-hud')) {

      section.setAttribute('data-hud', '');

    }

  }

  checkHud();

  function destroy() {

    section.outerHTML = "";

    SmoothScroll.on();

  }

}

export default Intro;
