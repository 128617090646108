// modules
import Sniffer from 'sniffer';
import SmoothScroll from '../scroll/scroll';

// projects
class Projects {
    constructor() {

        this.dom = {
            section: document.querySelector('.s-projects')
        };

        if (this.dom.section) this.init();
    }

    init() {

        const section = this.dom.section;
        const windowHeight = window.innerHeight;

        const cursor = document.querySelector('.a-cursor');

        const images = section.querySelectorAll('.c-image');
        const projects = section.querySelectorAll('.c-project');

        // images
        [...images].forEach((image) => {

            image.addEventListener('mouseenter', () => {

                cursor.insertAdjacentHTML('beforeend', '<label>View</label>');

                const label = cursor.querySelector('label');

                const splitter = new SplitText(label, {
                    type: 'chars'
                }), chars = splitter.chars;

                TweenMax.set(chars, {
                    x: 50,
                    opacity: 0
                });

                TweenMax.staggerTo(chars, 1, {
                    x: 0,
                    opacity: 0.99,
                    ease: Power3.easeOut
                }, 0.05);

            });

            image.addEventListener('mouseleave', () => {
                cursor.innerHTML = '';
            });

        });

        // projects
        [...projects].forEach((project) => {

            let src = project.querySelector('.c-src');

            project.addEventListener('mouseenter', () => {

                TweenMax.to(src, 1, {
                    scaleX: 1.05,
                    scaleY: 1.05,
                    ease: Power3.easeOut
                });

            });

            project.addEventListener('mouseleave', () => {

                TweenMax.to(src, 1, {
                    scaleX: 1,
                    scaleY: 1,
                    ease: Power3.easeOut
                });

            });

        });

        // scroll
        let raf;

        // init
        function scroll() {

            raf = undefined;

            [...projects].forEach((project) => {

                const object = project.querySelector('.c-src');

                let scrollY;

                if (!Sniffer.isPhone) {
                    scrollY = SmoothScroll.vars.current;
                }

                if (Sniffer.isPhone) {
                    const body = document.documentElement;
                    scrollY = (window.pageYOffset || body.scrollY) - (body.clientTop || 0);
                }

                let elementOffset = project.getBoundingClientRect().top + scrollY;
                let elementHeight = project.clientHeight;

                let distance = (scrollY + windowHeight) - elementOffset;
                let percentage = distance / ((windowHeight + elementHeight) / 100);

                if (percentage < 100) {

                    TweenMax.to(object, 0.2, {
                        yPercent: percentage / 4
                    });

                }

                return percentage;

            });

            start();
        }

        // start
        function start() {
            if (!raf) {
                raf = window.requestAnimationFrame(scroll);
            }
        }

        start();

        // stop
        function stop() {
            if (raf) {
                window.cancelAnimationFrame(raf);
                raf = undefined;
            }
        }

        // disable
        const links = document.getElementsByTagName('a');

        [...links].forEach((link) => {
            link.addEventListener("click", function() {
                stop();
            });
        });

    }

}

export default Projects;
