// modules
import Highway from '@dogstudio/highway';
import Loaded from 'imagesloaded';
import Sniffer from 'sniffer';

// settings
import HudColor from '../utils/hud-color';
import BackgroundColor from '../utils/bg-color';
import ImageFullScreen from '../utils/img-fullscreen';

// components
import Badge from '../../../src/components/badge/badge';
import Burger from '../../../src/components/burger/burger';
import BooksArchive from '../../../src/components/books/books-archive';
import Carousel from '../../../src/components/carousel/carousel';
import Category from '../../../src/components/category/category';
import CategoryNext from '../../../src/components/category/category-next';
import Collage from '../../../src/components/collage/collage';
import Cookies from '../../../src/components/cookies/cookies';
import CoreValues from '../../../src/components/core-values/core-values';
import Cursor from '../../../src/components/cursor/cursor';
import HeaderAbout from '../../../src/components/header/header-about';
import HeaderColor from '../../../src/components/header/header-color';
import HeaderImage from '../../../src/components/header/header-image';
import HeaderTitle from '../../../src/components/header/header-title';
import HeroAbout from '../../../src/components/hero/hero-about';
import HowWeWork from '../../../src/components/how-we-work/how-we-work';
import Images from '../../../src/components/images/images';
import Intro from '../../../src/components/intro/intro';
import JournalArchive from '../../../src/components/journal/journal-archive';
import JournalLatest from '../../../src/components/journal/journal-latest';
import Magazine from '../../../src/components/magazine/magazine';
import Magazines from '../../../src/components/magazines/magazines';
import Marquee from '../../../src/components/marquee/marquee';
import MediaOverview from '../../../src/components/media/media-overview';
import MediaSlider from '../../../src/components/media/media-slider';
import Menu from '../../../src/components/menu/menu';
import Partners from '../../../src/components/partners/partners';
import PortfolioArchive from '../../../src/components/portfolio/portfolio-archive';
import PortfolioList from '../../../src/components/portfolio/portfolio-list';
import Projects from '../../../src/components/projects/projects';
import ProjectsNext from '../../../src/components/projects/projects-next';
import SmoothScroll from '../../../src/components/scroll/scroll';
import SubCategory from '../../../src/components/subcategory/subcategory';
import Subscribe from '../../../src/components/subscribe/subscribe';
import Team from '../../../src/components/team/team';
import TestimonialSingle from '../../../src/components/testimonial/testimonial-single';
import TestimonialSlider from '../../../src/components/testimonial/testimonial-slider';
import Update from '../../../src/components/update/update';
import VideosArchive from '../../../src/components/videos/videos-archive';
import VideosSlider from '../../../src/components/videos/videos-slider';

// global
Intro();
Cursor();
Menu();
Burger();

// highway renderer
class Renderer extends Highway.Renderer {

  onEnter() {

    HudColor();

    const main = document.querySelector('.a-main');

    main.style.zIndex = '1';

  }

  onEnterCompleted() {

    if (!Sniffer.isPhone) {

      // reset hud
      // if (document.querySelector('.is-white')) {
      //     document.querySelectorAll('.is-white').classList.remove('is-white');
      // }

      // reset cursor
      document.querySelector('.a-cursor').innerHTML = '';

      // reset bg
      TweenMax.killTweensOf(document.querySelector('.a-background'));

      // settings
      HudColor();
      BackgroundColor();
      ImageFullScreen();

      // modules
      const cBadge = new Badge();
      const cBooksArchive = new BooksArchive();
      const cCarousel = new Carousel();
      const cCategory = new Category();
      const cCategoryNext = new CategoryNext();
      const cCollage = new Collage();
      const cCookies = new Cookies();
      const cCoreValues = new CoreValues();
      const cHeaderAbout = new HeaderAbout();
      const cHeaderColor = new HeaderColor();
      const cHeaderImage = new HeaderImage();
      const cHeaderTitle = new HeaderTitle();
      const cHeroAbout = new HeroAbout();
      const cHowWeWork = new HowWeWork();
      const cImages = new Images();
      const cJournalArchive = new JournalArchive();
      const cJournalLatest = new JournalLatest();
      const cMagazine = new Magazine();
      const cMagazines = new Magazines();
      const cMarquee = new Marquee();
      const cMediaOverview = new MediaOverview();
      const cMediaSlider = new MediaSlider();
      const cPartners = new Partners();
      const cPortfolioArchive = new PortfolioArchive();
      const cPortfolioList = new PortfolioList();
      const cProjects = new Projects();
      const cProjectsNext = new ProjectsNext();
      const cSubCategory = new SubCategory();
      const cSubscribe = new Subscribe();
      const cTeam = new Team();
      const cTestimonialSingle = new TestimonialSingle();
      const cTestimonialSlider = new TestimonialSlider();
      const cUpdate = new Update();
      const cVideosArchive = new VideosArchive();
      const cVideosSlider = new VideosSlider();

      // loaded
      const article = document.querySelector('.a-container');

      Loaded(article, function () {
        HudColor();
        SmoothScroll.resize();
      });

    }

    if (Sniffer.isPhone) {

      const cBadge = new Badge();
      const cCarousel = new Carousel();
      const cCategory = new Category();
      const cHeaderAbout = new HeaderAbout();
      const cJournalArchive = new JournalArchive();
      const cJournalLatest = new JournalLatest();
      const cMarquee = new Marquee();
      const cMediaSlider = new MediaSlider();
      const cPartners = new Partners();
      const cPortfolioArchive = new PortfolioArchive();
      const cProjects = new Projects();
      const cSubCategory = new SubCategory();
      const cTeam = new Team();
      const cTestimonialSingle = new TestimonialSingle();
      const cTestimonialSlider = new TestimonialSlider();
      const cUpdate = new Update();
      const cVideosArchive = new VideosArchive();

      // loaded
      const article = document.querySelector('.a-container');

      Loaded(article, function () {
        HudColor();
      });
    }
  }

  onLeaveCompleted() {
    document.querySelector('.a-background').style.backgroundColor = "#ffffff";

    if (Sniffer.isPhone) {
      window.scrollTo(0, 0);
    }

    if (!Sniffer.isPhone) {
      SmoothScroll.vars.current = SmoothScroll.vars.target = 0;
    }

  }

}

// export renderer
export default Renderer;
