// modules
import Sniffer from 'sniffer';
import SmoothScroll from '../scroll/scroll';

// core values
class MediaOverview {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-media-overview')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const windowHeight = window.innerHeight;

    const categories = section.querySelectorAll('.c-category');

    let raf;

    function rafScroll() {

      raf = undefined;

      let scrollTop = SmoothScroll.vars.current;

      [...categories].forEach((category) => {

        const object = category.querySelector('.c-object');

        let elementOffsetTop = category.getBoundingClientRect().top + scrollTop;
        let elementHeight = category.clientHeight;

        let distance = (scrollTop + windowHeight) - elementOffsetTop;
        let percentage = distance / ((windowHeight + elementHeight) / 200);

        if (percentage < 100) {

          let t = percentage / 100;
          let f = t * (2 - t);

          let ease = f * 30;

          if (category.classList.contains('c-right')) {
            TweenMax.to(object, 0.2, {
              force3D: true,
              rotation: ease / 2,
              xPercent: -ease,
              yPercent: -ease / 2
            });
          } else {
            TweenMax.to(object, 0.2, {
              force3D: true,
              rotation: -ease / 2,
              xPercent: ease,
              yPercent: -ease / 2
            });
          }

        }

        return percentage;

      });

      start();
    }

    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(rafScroll);
      }
    }

    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    start();

    // disable raf
    const links = document.getElementsByTagName('a');

    [...links].forEach((link) => {
      link.addEventListener('click', function () {
        stop();
      });
    }, false);

  }

}

export default MediaOverview;
