// modules
import Draggable from 'gsap/umd/Draggable';

// components
import SmoothScroll from '../../components/scroll/scroll';

// core values
class VideosArchive {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-videos-archive')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const cursor = document.querySelector('.a-cursor');
    const videos = document.querySelectorAll('.s-videos-archive .c-video');

    // archive
    [...videos].forEach((video) => {

      const preview = video.querySelector('.c-preview');
      const heading = video.querySelector('.f-heading-l');
      const thumbnail = video.querySelector('.c-thumbnail');

      const src = video.getElementsByTagName('video')[0];
      const vimeo = video.querySelector('.c-preview').dataset.src;

      video.addEventListener('mouseenter', (e) => {

        e.preventDefault();

        cursor.insertAdjacentHTML('beforeend', '<label>Play</label>');

        const label = cursor.querySelector('label');

        const splitter = new SplitText(label, {
          type: 'chars'
        }),
          chars = splitter.chars;

        TweenMax.set(chars, {
          x: 50,
          opacity: 0
        });

        TweenMax.staggerTo(chars, 1, {
          x: 0,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.05);

        TweenMax.to(heading, 0.5, {
          opacity: 0
        });

        TweenMax.to(thumbnail, 1, {
          opacity: 0
        });

        src.play();

      });

      video.addEventListener('mouseleave', (e) => {

        e.preventDefault();

        cursor.innerHTML = '';

        src.pause();

        TweenMax.to(heading, 0.5, {
          opacity: 0.99
        });

        TweenMax.to(thumbnail, 1, {
          opacity: 0.99
        });

      });

      video.addEventListener('click', (e) => {

        e.preventDefault();

        preview.style.zIndex = 3;

        cursor.innerHTML = '';

        open(video, vimeo);

        section.style.pointerEvents = 'none';

      });

    });

    // load
    function open(video, vimeo) {

      SmoothScroll.off();

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const width = video.offsetWidth;
      const height = video.offsetHeight;

      const offset = video.getBoundingClientRect();

      const top = offset.top;
      const left = offset.left;
      const right = offset.right;

      if (document.querySelector('.s-overlay-video')) {
        document.querySelector('.s-overlay-video').remove();
      }

      // overlay
      document.querySelector('.a-overlay')
        .insertAdjacentHTML('beforeend',
          '<div class="s-overlay-video"></div>'
        );

      const overlay = document.querySelector('.s-overlay-video');

      // src
      overlay.insertAdjacentHTML('beforeend',
        '<div class="c-frame"><video class="c-vimeo"><source src="' + vimeo + '" type="video/mp4"></video></div>'
      );

      const src = overlay.querySelector('.c-vimeo');

      // controls
      overlay.insertAdjacentHTML('beforeend',
        '<div class="c-controls is-hidden"></div>'
      );

      const controls = overlay.querySelector('.c-controls');

      // pause
      controls.insertAdjacentHTML('beforeend',
        '<div class="c-pause"><span class="f-heading-r">Pause</span></div>'
      );

      const pause = controls.querySelector('.c-pause');
      const pauseLabel = pause.querySelector('.f-heading-r');

      pause.addEventListener('click', () => {

        pause.classList.toggle('is-paused');

        if (pause.classList.contains('is-paused')) {
          src.pause();
          pauseLabel.innerHTML = 'Play';
        } else {
          src.play();
          pauseLabel.innerHTML = 'Pause';
        }

      });

      // mute
      controls.insertAdjacentHTML('beforeend',
        '<div class="c-mute"><span class="f-heading-r">Mute</span></div>'
      );

      const mute = controls.querySelector('.c-mute');
      const muteLabel = mute.querySelector('.f-heading-r');

      mute.addEventListener('click', () => {

        mute.classList.toggle('is-muted');

        if (mute.classList.contains('is-muted')) {
          src.muted = true;
          muteLabel.innerHTML = 'Unmute';
        } else {
          src.muted = false;
          muteLabel.innerHTML = 'Mute';
        }

      });

      // progress
      controls.insertAdjacentHTML('beforeend',
        '<div class="c-progress"><div class="c-drag"></div><div class="c-filler"></div></div>'
      );

      // dragger
      const drag = controls.querySelector('.c-drag');
      const filler = controls.querySelector('.c-filler');
      const progress = controls.querySelector('.c-progress');

      const dragger = new Draggable(drag, {
        type: "x",
        cursor: 'grab',
        minDuration: 0,
        bounds: progress,
        throwProps: false,
        onDrag: isDragging,
        zIndexBoost: false,
        throwResistance: 999999,
      });

      // dragger update
      function isDragging() {

        let xp = this.x / (this.maxX || 1);
        let frame = (xp.toFixed(2));
        let time = src.duration * (frame);

        src.currentTime = time;

        TweenLite.set(drag, {
          cursor: "grabbing"
        });

        TweenMax.set(filler, {
          width: this.x
        });

      }

      // time update
      src.addEventListener('timeupdate', () => {

        let width = progress.offsetWidth;
        let value = (100 / src.duration) * src.currentTime;
        let length = (width * value) / 100;

        TweenMax.set(drag, {
          x: length
        });

        TweenMax.set(filler, {
          width: length
        });

      });

      // user activity
      var userActivity;

      function setup() {
        overlay.addEventListener('mousemove', resetTimer, false);

        startTimer();
      }

      setup();

      function startTimer() {
        userActivity = window.setTimeout(isInactive, 3000);
      }

      function resetTimer(e) {
        window.clearTimeout(userActivity);

        isActive();
      }

      function isInactive() {
        if (document.querySelector('.c-controls')) {
          document.querySelector('.c-controls').classList.add('is-idle');
        }
      }

      function isActive() {
        if (document.querySelector('.c-controls')) {
          document.querySelector('.c-controls').classList.remove('is-idle');
        }
        startTimer();
      }

      // close
      src.addEventListener('mouseenter', () => {

        cursor.insertAdjacentHTML('beforeend', '<label>Close</label>');

        const label = cursor.querySelector('label');

        const splitter = new SplitText(label, {
          type: 'chars'
        }),
          chars = splitter.chars;

        TweenMax.set(chars, {
          x: 50,
          opacity: 0
        });

        TweenMax.staggerTo(chars, 1, {
          x: 0,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.05);

      });

      // leave
      src.addEventListener('mouseleave', () => {
        cursor.innerHTML = '';
      });

      // click
      src.addEventListener('click', (e) => {

        cursor.innerHTML = '';

        e.preventDefault();

        SmoothScroll.on();

        document.querySelector('.s-overlay-video').remove();

        section.removeAttribute('style');

      });

      // tween
      const preview = video.querySelector('.c-preview');

      overlay.style.width = '' + width + 'px';
      overlay.style.height = '' + height + 'px';
      overlay.style.transform = 'translate(' + left + 'px, ' + top + 'px)';

      setTimeout(function () {

        TweenMax.to(overlay, 1, {
          y: 0,
          x: 0,
          width: windowWidth,
          height: windowHeight,
          ease: Power3.easeInOut,
          onComplete: controlsShow
        });

        function controlsShow() {

          preview.style.zIndex = 1;
          controls.classList.remove('is-hidden');

          overlay.style.width = '100%';
          overlay.style.height = '100%';

        }

        src.play();

      }, 500);

    }

  }

}

export default VideosArchive;
