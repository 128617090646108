// gsap
import Loaded from 'imagesloaded';
import Draggable from 'gsap/umd/Draggable';
import ThrowPropsPlugin from 'gsap';

// partners
class Partners {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-partners')
    };

    if (this.dom.section) this.init();
  }

  images() {

    const section = this.dom.section;

    const images = section.querySelectorAll('.c-src');
    const dragger = section.querySelector('.c-dragger');
    const container = section.querySelector('.c-container');

    Loaded(images, function (instance) {

      [...images].forEach((image) => {

        const parentImage = image.parentNode;
        const parentColumn = parentImage.parentNode;

        if (image.naturalHeight > image.naturalWidth) {
          parentColumn.classList.add("is-portrait")
        } else {
          parentColumn.classList.add("is-landscape")
        }

        let draggerHeight = container.clientHeight;

        dragger.style.height = '' + draggerHeight + 'px'

      });

    });

  }

  dragger() {

    const section = this.dom.section;

    const cursor = document.querySelector('.a-cursor');

    const images = section.querySelectorAll('.c-image');
    const dragger = section.querySelector('.c-dragger');
    const container = section.querySelector('.c-container');

    let draggerWidth = 0;
    let draggerCols = section.children;

    for (let i = 0; i < draggerCols.length; i++) {
      draggerWidth += draggerCols[i].offsetWidth;
    }

    // tweens
    function scaleIn() {
      TweenMax.to(images, 0.5, {
        scale: 0.95,
        ease: Power3.easeOut
      });
    }

    function scaleOut() {
      TweenMax.to(images, 0.5, {
        scale: 1,
        ease: Power3.easeOut
      });
    }

    // dragger
    Draggable.create(container, {
      type: "x",
      cursor: 'grab',
      bounds: dragger,
      throwProps: true,
      zIndexBoost: false,
      edgeResistance: 0.65,
      onDrag: function () {

        scaleIn();

        TweenLite.set(container, {
          cursor: "grabbing"
        });

      },
      onRelease: function () {

        scaleOut();

      }
    });

    // cursor
    [...images].forEach((image) => {

      image.addEventListener('mouseenter', () => {

        cursor.insertAdjacentHTML('beforeend', '<label>Drag</label>');

        const label = cursor.querySelector('label');

        const splitter = new SplitText(label, {
          type: 'chars'
        }), chars = splitter.chars;

        TweenMax.set(chars, {
          x: 50,
          opacity: 0
        });

        TweenMax.staggerTo(chars, 1, {
          x: 0,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.05);

      });

      image.addEventListener('mouseleave', () => {
        cursor.innerHTML = '';
      });

    });

  }

  init() {
    this.images();
    this.dragger();
  }

}

export default Partners;
