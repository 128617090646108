// modules
import Highway from '@dogstudio/highway';

// components
import SmoothScroll from '../../components/scroll/scroll';

// Fade
class HeaderTransition extends Highway.Transition {

    in ({
        from,
        to,
        trigger,
        done
    }) {

        const placeholder = document.querySelector('.s-overlay-placeholder');

        const header = to.querySelector('.s-header-title');

        const back = header.querySelector('.c-back');
        const badge = header.querySelector('.c-badge');
        const chapeau = header.querySelector('.c-text .f-chapeau');

        TweenMax.set(back, {
            opacity: 0
        });

        TweenMax.set(badge, {
            yPercent: 150
        });

        TweenMax.set(chapeau, {
            opacity: 0
        });

        // from - remove
        from.remove();

        // to - done
        done();

        setTimeout(function() {

            // placeholder - remove
            placeholder.remove();

            // tween
            TweenMax.to(back, 1, {
                opacity: 1,
                clearProps: 'all',
                ease: Power3.easeInOut
            });

            TweenMax.to(badge, 2, {
                yPercent: 0,
                clearProps: 'all',
                ease: Power3.easeInOut
            });

            TweenMax.to(chapeau, 1, {
                opacity: 0.8,
                clearProps: 'all',
                ease: Power3.easeInOut
            });

        }, 500);

    }

    out({
        from,
        trigger,
        done
    }) {

        SmoothScroll.off();

        const scrollY = SmoothScroll.vars.current;

        const overlay = document.querySelector('.a-overlay');

        const windowHeight = window.innerHeight;

        const section = from.querySelector('.s-category-next');

        const src = section.querySelector('.c-src');
        const banner = section.querySelector('.c-banner');
        const chapeau = section.querySelector('.f-chapeau');

        const sectionTop = getOffset(section).top;

        // offset
        function getOffset(el) {
            const rect = el.getBoundingClientRect();
            return {
                left: rect.left,
                top: rect.top + scrollY
            };
        }

        // tween
        TweenMax.to(chapeau, 1, {
            opacity: 0,
            ease: Power3.easeInOut
        });

        TweenMax.to(section, 1, {
            y: scrollY - sectionTop,
            ease: Power3.easeInOut
        });

        TweenMax.to(src, 1, {
            top: 0,
            scale: 1,
            yPercent: 0,
            force3D: true,
            height: windowHeight,
            ease: Power3.easeInOut,
            onComplete: initPlaceholder
        });

        // placeholder
        function initPlaceholder() {

            overlay.insertAdjacentHTML('beforeend', '<div class="s-overlay-placeholder"></div>');

            const placeholder = document.querySelector('.s-overlay-placeholder');
            const object = placeholder.appendChild(banner.cloneNode(true));

            // scroller
            SmoothScroll.on();

            SmoothScroll.vars.current = SmoothScroll.vars.target = 0;

            // from - done
            done();

        }

    }
}

export default HeaderTransition;
