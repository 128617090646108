// components
import SmoothScroll from '../scroll/scroll';

// menu
const Menu = function () {

  const logo = document.querySelector('.a-logo');
  const menu = document.querySelector('.a-menu');
  const burger = document.querySelector('.a-burger');

  const image = menu.querySelector('.c-image');
  const images = menu.querySelectorAll('.c-src');
  const buttons = menu.querySelectorAll('li a');
  const nav = menu.querySelectorAll('.c-nav li');
  const subnav = menu.querySelectorAll('.c-subnav li');
  const container = menu.querySelector('.c-container');

  const langmenu = menu.querySelector('.c-language');
  const language = langmenu.querySelectorAll('a');

  function randomImg() {

    let imagesArray = [].slice.call(menu.querySelectorAll('.c-src'));

    [...images].forEach((image) => {
      image.style.display = 'none';
    });

    let random = Math.floor(Math.random() * (imagesArray.length));

    imagesArray[random].style.display = 'block';
  }

  function tiltImg() {

    menu.addEventListener('mousemove', function (e) {

      let x = (e.clientX / window.innerWidth) - 0.5;
      let y = (e.clientY / window.innerHeight) - 0.5;

      const TweenPosition = new TimelineMax();

      TweenPosition.to(image, 0.6, {
        rotationY: 10 * x,
        rotationX: 10 * y,
        ease: Power1.easeOut,
        transformOrigin: 'center',
        transformPerspective: 900
      });

    });

  }

  tiltImg();

  function initMenu() {

    const tl = new TimelineMax();

    tl
      .set(nav, {
        y: -50,
        opacity: 0
      })
      .set(subnav, {
        y: -10,
        opacity: 0
      })
      .set(langmenu, {
        opacity: 0
      })
      .set(image, {
        scale: 1.5
      })
      .set(menu, {
        opacity: 1,
        yPercent: -100
      })
      .set(container, {
        yPercent: 100
      });

  }

  initMenu();

  function openMenu() {

    const article = document.querySelector('.a-container');

    initMenu();
    randomImg();

    SmoothScroll.off();

    logo.classList.add('is-dp');
    menu.classList.add('is-visible');

    const tl = new TimelineMax();

    tl
      .staggerTo(nav, 1, {
        y: 0,
        opacity: 0.99,
        clearProps: 'all',
        ease: Power3.easeOut
      }, 0.1, 0.5)
      .staggerTo(subnav, 1, {
        y: 0,
        opacity: 0.99,
        clearProps: 'all',
        ease: Power3.easeOut
      }, 0.1, 0.5)
      .to(langmenu, 1, {
        opacity: 0.99,
        clearProps: 'all',
        ease: Power3.easeInOut
      }, 1)
      .to(image, 2.5, {
        scale: 1,
        ease: Power3.easeOut
      }, 0)
      .to(article, 1, {
        y: 150,
        clearProps: 'all',
        ease: Power3.easeInOut
      }, 0)
      .to(menu, 1, {
        yPercent: 0,
        ease: Power3.easeInOut
      }, 0)
      .to(container, 1, {
        yPercent: 0,
        ease: Power3.easeInOut
      }, 0);

  }

  function closeMenu() {

    const article = document.querySelector('.a-container');

    SmoothScroll.on();

    menu.classList.remove('is-visible');

    const tl = new TimelineMax();

    tl
      .set(article, {
        y: -150
      }, 0)
      .to(article, 1, {
        y: 0,
        clearProps: 'all',
        ease: Power3.easeInOut
      }, 0)
      .to(menu, 1, {
        yPercent: 100,
        ease: Power3.easeInOut
      }, 0)
      .to(container, 1, {
        yPercent: -100,
        ease: Power3.easeInOut
      }, 0);

  }

  function events() {

    [...language].forEach((link) => {
      link.setAttribute('data-router-disabled', '');
    });

    [...buttons].forEach((button) => {
      button.addEventListener('click', () => {
        setTimeout(function () {
          closeMenu();
        }, 1000);
      });
    });

    burger.addEventListener('click', () => {

      if (menu.classList.contains('is-visible')) {
        closeMenu();
      } else {
        openMenu();
      }

    });

  }

  events();

}

export default Menu;
