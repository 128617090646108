// modules
import Sniffer from 'sniffer';

// components
import SmoothScroll from '../scroll/scroll';

// image zoom
const Logo = function () {

  let scrollY;

  if (Sniffer.isDesktop) {
    scrollY = SmoothScroll.vars.current;
  }

  if (Sniffer.isPhone) {
    const body = document.documentElement;
    scrollY = (window.pageYOffset || body.scrollTop) - (body.clientTop || 0);
  }

  let logo = document.querySelector('.a-logo');

  let emblem = logo.querySelector('.c-emblem');
  let wordmark = logo.querySelector('.c-wordmark');

  if (scrollY > 100) {

    logo.classList.add('is-scrolled');

    TweenMax.to(emblem, 0.5, {
      opacity: 1
    });

    TweenMax.to(wordmark, 0.5, {
      opacity: 0
    });

  } else if (scrollY <= 100) {

    logo.classList.remove('is-scrolled');

    TweenMax.to(emblem, 0.5, {
      opacity: 0
    });

    TweenMax.to(wordmark, 0.5, {
      opacity: 1
    });

  }

}

export default Logo;
