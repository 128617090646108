// header image
class HeaderTitle {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-header-title')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const cursor = document.querySelector('.a-cursor');

    section.addEventListener('mouseenter', () => {

      cursor.insertAdjacentHTML('beforeend', '<label>Scroll</label>');

      const label = cursor.querySelector('label');

      const splitter = new SplitText(label, {
        type: 'chars'
      }), chars = splitter.chars;

      TweenMax.set(chars, {
        x: 50,
        opacity: 0
      });

      TweenMax.staggerTo(chars, 1, {
        x: 0,
        opacity: 0.99,
        ease: Power3.easeOut
      }, 0.05);

    });

    section.addEventListener('mouseleave', () => {
      cursor.innerHTML = '';
    });

  }

}

export default HeaderTitle;
