// modules
import Loaded from 'imagesloaded';
import SmoothScroll from '../scroll/scroll';

// journal
class HeaderColor {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-header-color')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const background = document.querySelector('.a-background');

    const color = section.querySelector('.c-color').dataset.color;
    const image = section.querySelector('.c-src');

    background.style.backgroundColor = color;

    Loaded(image, function (instance) {

      const parentImage = image.parentNode;

      if (image.naturalHeight > image.naturalWidth) {
        parentImage.classList.add("is-portrait")
      } else {
        parentImage.classList.add("is-landscape")
      }

      SmoothScroll.resize();

    });

  }

}

export default HeaderColor;
