// styles
import './index.scss';

// imports
import Slide from './js/transitions/slide';
import Highway from '@dogstudio/highway';
import Renderer from './js/renderers/renderer';
import MenuTransition from './js/transitions/menu';
import NextTranstion from './js/transitions/next';
import BookTransition from './js/transitions/book';
import HeaderTransition from './js/transitions/header';

// highway
const H = new Highway.Core({
  renderers: {
    renderer: Renderer
  },
  transitions: {
    default: Slide,
    contextual: {
      menu: MenuTransition,
      book: BookTransition,
      next: NextTranstion,
      header: HeaderTransition
    }
  }
});

// update links
const menuLinks = document.querySelectorAll('.a-menu a');
const navLinks = document.querySelectorAll('.a-nav a');

// set data
[...menuLinks].forEach((menuLink) => {
  menuLink.setAttribute('data-transition', 'menu');
});

// navigate in
H.on('NAVIGATE_IN', ({
  to,
  location
}) => {

  for (let i = 0; i < menuLinks.length; i++) {
    const menuLink = menuLinks[i];

    menuLink.classList.remove('is-active');

    if (menuLink.href === location.href) {
      menuLink.classList.add('is-active');
    }
  }

  for (let i = 0; i < navLinks.length; i++) {
    const navLink = navLinks[i];

    navLink.classList.remove('is-active');

    if (navLink.href === location.href) {
      navLink.classList.add('is-active');
    }
  }

});

// navigate end
H.on('NAVIGATE_END', ({
  from,
  to,
  location
}) => {
  // Analytics
  if (typeof gtag !== 'undefined') {
    // eslint-disable-next-line
    gtag('config', 'UA-136081007-1', {
      'page_path': location.pathname,
      'page_title': to.page.title,
      'page_location': location.href
    });
  }
});

// copyright
console.log("%cCrafted by Exo Ape - Coded by Rob Smittenaar", "color: white; background: black; padding: 5px 20px");
