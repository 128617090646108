// modules
import Loaded from 'imagesloaded';
import Highway from '@dogstudio/highway';

// transition
class MenuTransition extends Highway.Transition {

    in ({
        from,
        to,
        trigger,
        done
    }) {

        const container = document.querySelector('.a-container');

        from.remove();

        Loaded(container, function(instance) {

            done();

        });
    }

    out({
        from,
        trigger,
        done
    }) {

        done();

    }
}

export default MenuTransition;
