// core values
class Subscribe {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-subscribe')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const accept = section.querySelector('.f-paragraph');
    const button = section.querySelector('.f-button');

    accept.addEventListener('click', () => {

      if (accept.classList.contains('is-accepted')) {

        accept.classList.remove('is-accepted');
        button.classList.add('is-disabled');

      } else {

        accept.classList.add('is-accepted');
        button.classList.remove('is-disabled');

      }
    });

  }

}

export default Subscribe;
