const HudColor = function() {

    const nav = document.querySelector('.a-nav');
    const logo = document.querySelector('.a-logo');
    const burger = document.querySelector('.a-burger');
    // const awwwards = document.querySelector('.a-awwwards');

    const hud = document.querySelectorAll('[data-hud=""]');

    let logoClass = false;
    let logoTop = logo.offsetTop;
    let logoHeight = logo.clientHeight;

    // let awwwardsClass = false;
    // let awwwardsTop = awwwards.offsetTop;
    // let awwwardsHeight = awwwards.clientHeight;

    [...hud].forEach((section) => {

        let bounding = section.getBoundingClientRect();
        let boundingTop = bounding.top;
        let boundingHeight = section.clientHeight;

        if (logoTop + logoHeight < boundingTop) {

        } else if (logoTop > boundingTop + boundingHeight) {

        } else {
            logoClass = true;
        }

        // if (awwwardsTop + awwwardsHeight < boundingTop) {
        //
        // } else if (awwwardsTop > boundingTop + boundingHeight) {
        //
        // } else {
        //     awwwardsClass = true;
        // }

    });

    if (logoClass == true) {

        nav.classList.add('is-dp');
        logo.classList.add('is-dp');
        burger.classList.add('is-dp');

    } else {

        nav.classList.remove('is-dp');
        logo.classList.remove('is-dp');
        burger.classList.remove('is-dp');

    }

    // if (awwwardsClass == true) {
    //
    //     awwwards.classList.add('is-dp');
    //
    // } else {
    //
    //     awwwards.classList.remove('is-dp');
    //
    // }

    return;

}

export default HudColor;
