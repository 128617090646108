import Sniffer from 'sniffer';

class Update {
  constructor() {
    this.dom = {
      section: document.querySelector('.s-update')
    };

    if (this.dom.section) this.init();
  }

  marquee() {
    const marquee = document.querySelector('.s-update-marquee .f-heading-xl');

    setTimeout(function () {
      const width = marquee.getBoundingClientRect().width;
      TweenMax.to(marquee, 15, {
        repeat: -1,
        x: -width / 2,
        ease: Linear.easeNone,
      });
    }, 1500);
  }

  set() {
    const image = document.querySelector('.s-update-image');

    TweenMax.to(image, 1, {
      ease: Power3.easeOut,
      y: window.innerWidth * 0.05903,
      x: window.innerWidth * 0.16667,
    });
  }

  events() {
    const section = document.querySelector('.s-update');
    const image = document.querySelector('.s-update-image');
    const marquee = document.querySelector('.s-update-marquee .f-heading-xl');

    marquee.addEventListener('mousemove', (e) => {

      // let y = e.clientY - image.getBoundingClientRect().height / 2;
      let x = e.clientX - image.getBoundingClientRect().width / 2;

      TweenMax.to(image, 1, {
        x: window.innerWidth * 0.16667 + (x * 0.05),
        // y: y - section.getBoundingClientRect().top,
      });

    });

    marquee.addEventListener('mouseleave', () => {

      TweenMax.to(image, 1, {
        ease: Power3.easeOut,
        // y: window.innerWidth * 0.05903,
        x: window.innerWidth * 0.16667,
      });

    });
  }

  init() {
    this.marquee();
    if (Sniffer.isDevice) return;
    this.events();
    this.set();
  }
}

export default Update;
