// modules
import SmoothScroll from '../scroll/scroll';

// books archive
class BooksArchive {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-books')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const windowHeight = window.innerHeight;

    const books = section.querySelectorAll('.c-book');

    // scroll
    let raf;

    function scroll() {

      raf = undefined;

      let scrollY = SmoothScroll.vars.current;

      [...books].forEach((book) => {

        const image = book.querySelector('.c-img');

        let offset = image.getBoundingClientRect().top + scrollY;
        let height = image.clientHeight;

        let distance = (scrollY + windowHeight) - offset;
        let percentage = distance / ((windowHeight + height) / 100);

        if (percentage < 100) {

          if (book.classList.contains('c-right')) {
            TweenMax.to(image, 0.2, {
              yPercent: percentage / 3
            });
          } else {
            TweenMax.to(image, 0.2, {
              yPercent: percentage / 3
            });
          }

        }

        return percentage;

      });

      start();
    }

    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(scroll);
      }
    }

    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    start();

    // disable raf
    const links = document.getElementsByTagName('a');

    [...links].forEach((link) => {
      link.addEventListener('click', function () {
        stop();
      });
    });

    // enable transition
    const buttons = section.querySelectorAll('.f-button');

    [...buttons].forEach((button) => {

      button.setAttribute('data-transition', 'book');

      button.addEventListener('mouseenter', function () {
        const active = button.closest('.c-book');
        active.classList.add('is-active');
      });

      button.addEventListener('mouseleave', function () {
        const active = button.closest('.c-book');
        active.classList.remove('is-active');
      });
    });

  }

}

export default BooksArchive;
