// cursor
const Cursor = function () {

  const cursor = document.querySelector('.a-cursor');

  document.addEventListener('mousemove', function (e) {

    let x = (e.clientX);
    let y = (e.clientY);

    TweenMax.to(cursor, 0.2, {
      x: x,
      y: y
    });

  });

}

export default Cursor;
