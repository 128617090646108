// components
import SmoothScroll from '../../components/scroll/scroll';

// viewport
import { isInViewport } from './is-in-viewport';

// image parallax
const ImageParallax = function () {

  let parallax = document.querySelectorAll('.js-parallax');
  let scrollY = SmoothScroll.vars.current;

  if (!parallax.length) return;

  [...parallax].forEach((el) => {

    if (isInViewport(el)) {

      let disabled = el.dataset.parallax;

      if (disabled === 'zoom') {
        TweenMax.set(el, {
          scale: 1 + (scrollY / 5000)
        });
      } else {
        TweenMax.set(el, {
          y: scrollY / 4,
          scale: 1 + (scrollY / 5000)
        });
      }

      return;

    }

  });

}

export default ImageParallax;
