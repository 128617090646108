// modules
import SmoothScroll from '../scroll/scroll';

// portfolio archive
class PortfolioArchive {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-portfolio-archive'),
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const windowHeight = window.innerHeight;

    const cursor = document.querySelector('.a-cursor');

    const images = section.querySelectorAll('.c-image');
    const categories = section.querySelectorAll('.c-category');

    // cursor
    [...images].forEach((image) => {

      image.addEventListener('mouseenter', () => {

        cursor.insertAdjacentHTML('beforeend', '<label>View</label>');

        const label = cursor.querySelector('label');

        const splitter = new SplitText(label, {
          type: 'chars'
        }), chars = splitter.chars;

        TweenMax.set(chars, {
          x: 50,
          opacity: 0
        });

        TweenMax.staggerTo(chars, 1, {
          x: 0,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.05);

      });

      image.addEventListener('mouseleave', () => {
        cursor.innerHTML = '';
      });

    });

    // ratio
    function ratio(category) {

      let ratio;

      let image = category.querySelector('.c-image');
      let letter = category.querySelector('.c-letter');

      let svg = category.getElementsByTagName("svg")[0];
      let svgImage = category.querySelector(".c-object");

      let width = image.offsetWidth
      let height = image.offsetHeight;

      if (height > width) {
        ratio = height;
      } else {
        ratio = width;
      }

      svg.setAttribute("viewBox", "0 0 " + ratio + " " + ratio + "");
      svg.setAttribute("height", "" + ratio + "px");
      svg.setAttribute("width", "" + ratio + "px");

      svgImage.setAttribute("height", "" + ratio + "px");
      svgImage.setAttribute("width", "" + ratio + "px");

      letter.style.width = "" + ratio + "px";
      letter.style.height = "" + ratio + "px";

    }

    // resize
    window.onresize = function () {
      [...categories].forEach((category) => {
        ratio(category);
      });
    };

    // set
    [...categories].forEach((category) => {
      ratio(category);
    });

    // events
    [...categories].forEach((category) => {

      let src = category.querySelector('.c-src');

      category.addEventListener('mouseenter', () => {

        TweenMax.to(src, 1, {
          scaleX: 1.05,
          scaleY: 1.05,
          ease: Power3.easeOut
        });

      });

      category.addEventListener('mouseleave', () => {

        TweenMax.to(src, 1, {
          scaleX: 1,
          scaleY: 1,
          ease: Power3.easeOut
        });

      });

    });

    // scroll
    let raf;

    // init
    function scroll() {

      raf = undefined;

      [...categories].forEach((category) => {

        const src = category.querySelector('.c-src');
        const letter = category.querySelector('text');

        let scrollY;

        scrollY = SmoothScroll.vars.current;

        let elementOffsetTop = category.getBoundingClientRect().top + scrollY;
        let elementHeight = category.clientHeight;

        let distance = (scrollY + windowHeight) - elementOffsetTop;
        let percentage = distance / ((windowHeight + elementHeight) / 100);

        if (percentage < 100) {

          TweenMax.to(letter, 0.2, {
            delay: 0.1,
            yPercent: percentage / 8
          });

          TweenMax.to(src, 0.2, {
            yPercent: percentage / 4
          });

        }

        return percentage;

      });

      start();
    }

    // start
    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(scroll);
      }
    }

    start();

    // stop
    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    // disable
    const links = document.getElementsByTagName('a');

    [...links].forEach((link) => {
      link.addEventListener('click', (e) => {

        stop();

        window.onresize = null

      });
    }, false);

  }

}

export default PortfolioArchive;
