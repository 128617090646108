// modules
import Sniffer from 'sniffer';
import Loaded from 'imagesloaded';
import SmoothScroll from '../scroll/scroll';

// viewport
import { isInViewport } from '../../js/utils/is-in-viewport';

// images
class Images {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-images')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const links = document.getElementsByTagName('a');

    const sections = document.querySelectorAll('.s-images');

    // images
    [...sections].forEach((section) => {

      const images = section.querySelectorAll('.c-image');

      Loaded(images, function (instance) {

        [...images].forEach((image) => {

          const tl = new TimelineMax();

          tl
            .set(image, {
              y: 200,
              scale: 1.2,
              opacity: 0
            })

        });

        SmoothScroll.resize();

      });

    });

    // scroll
    let raf;

    function scroll() {

      raf = undefined;

      [...sections].forEach((section) => {

        const images = section.querySelectorAll('.c-image');

        [...images].forEach((image) => {

          if (isInViewport(image)) {

            const tl = new TimelineMax();

            tl.to(image, 1.5, {
              y: 0,
              scale: 1,
              opacity: 0.99,
              ease: Power3.easeOut
            });

            return;

          }

        });

      });

      start();
    }

    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(scroll);
      }
    }

    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    start();

    // disable raf
    [...links].forEach((link) => {
      link.addEventListener('click', function () {
        stop();
      });
    }, false);

  }

}

export default Images;
