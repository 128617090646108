// media slider
class MediaSlider {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-media-slider')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const background = document.querySelector('.a-background');

    const nav = section.querySelector('.c-nav');
    const player = section.querySelector('.c-player');
    const texts = section.querySelectorAll('.c-text .c-slide');
    const images = section.querySelectorAll('.c-image .c-slide');
    const buttons = section.querySelectorAll('.c-nav .f-chapeau');
    const staggers = section.querySelectorAll('.c-text .c-slide .c-stagger');

    let activeText = texts[0];
    let activeImage = images[0];
    let activeButton = buttons[0];
    let activeStagger = activeText.querySelectorAll('.c-stagger');

    let slide;
    let color;

    let nextText;
    let nextImage;
    let nextStagger;

    activeText.classList.add('is-active');
    activeImage.classList.add('is-active');
    activeButton.classList.add('is-active');

    const tl = new TimelineMax();

    tl
      .set(images, {
        rotation: 90,
        xPercent: 150
      })
      .set(staggers, {
        y: 50,
        opacity: 0
      })
      .set(activeImage, {
        rotation: 0,
        xPercent: 0
      })
      .set(activeText, {
        opacity: 0.99
      })
      .set(activeStagger, {
        y: 0,
        opacity: 1
      });

    // events
    [...buttons].forEach((button) => {
      button.addEventListener('click', () => {

        slide = button.dataset.slide;
        color = button.dataset.color;

        buttons.forEach(e => {
          e.classList.remove('is-active');
        });

        button.classList.add('is-active');

        slideIn();

      });
    });

    function slideIn() {

      activeText = section.querySelector('.c-text .is-active');
      activeImage = section.querySelector('.c-image .is-active');

      nextText = section.querySelector('[data-text=' + slide + ']');
      nextImage = section.querySelector('[data-image=' + slide + ']');

      nextStagger = nextText.querySelectorAll('.c-stagger');
      activeStagger = activeText.querySelectorAll('.c-stagger');

      nextText.classList.add('is-active');
      nextImage.classList.add('is-active');

      activeText.classList.remove('is-active');
      activeImage.classList.remove('is-active');

      nav.style.pointerEvents = 'none';

      const tl = new TimelineMax();

      tl
        .to(background, 0.75, {
          backgroundColor: color
        }, 0)
        .to(activeImage, 1.5, {
          xPercent: -150,
          ease: Power3.easeInOut
        }, 0)
        .staggerTo(activeStagger, 1, {
          y: -50,
          opacity: 0,
          ease: Power3.easeIn
        }, 0.1, 0)
        .staggerTo(nextStagger, 1, {
          y: 0,
          opacity: 1,
          onComplete: setStates,
          ease: Power3.easeOut
        }, 0.1, 1)
        .to(nextImage, 1.5, {
          xPercent: 0,
          rotation: 0,
          ease: Power3.easeInOut
        }, 0);

    }

    // set
    function setStates() {

      nav.style.pointerEvents = '';

      if (section.querySelector('[data-image="videos"]').classList.contains('is-active')) {
        player.play();
      } else {
        player.pause();
      }

      const tl = new TimelineMax();

      tl
        .set(images, {
          rotation: 90,
          xPercent: 150
        })
        .set(staggers, {
          y: 50,
          opacity: 0
        })
        .set(nextStagger, {
          y: 0,
          opacity: 1
        })
        .set(nextImage, {
          rotation: 0,
          xPercent: 0,
        });

    }
  }
}

export default MediaSlider;
