// about
class HeaderAbout {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-header-about'),
    };

    if (this.dom.section) this.init();
  }

  init() {
    document.querySelector('.a-nav').classList.add('is-dp');
    document.querySelector('.a-logo').classList.add('is-dp');
    document.querySelector('.a-burger').classList.add('is-dp');
  }

}

export default HeaderAbout;
