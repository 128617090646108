// core values
class CoreValues {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-core-values')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const logo = document.querySelector('.a-logo');
    const burger = document.querySelector('.a-burger');

    const collage = document.querySelector('.s-collage');
    const update = document.querySelector('.s-update');

    const columns = section.querySelectorAll('.c-column');

    const lastcolumn = columns[2]
    const firstcolumn = columns[0]

    // events
    firstcolumn.addEventListener('mouseenter', () => {
      logo.classList.add('is-white');
    });

    firstcolumn.addEventListener('mouseleave', () => {
      logo.classList.remove('is-white');
    });

    lastcolumn.addEventListener('mouseenter', () => {
      burger.classList.add('is-white');
    });

    lastcolumn.addEventListener('mouseleave', () => {
      burger.classList.remove('is-white');
    });

    // hovers
    [...columns].forEach((column) => {

      const image = column.querySelector('.c-image');
      const imageSrc = column.querySelector('.c-src');
      const imageMask = column.querySelector('.c-image .c-mask');

      const textCopy = column.querySelector('.c-copy');
      const textHeading = column.querySelector('.c-heading');
      const textMask = column.querySelector('.c-text .c-mask');
      const textParagraph = column.querySelector('.f-paragraph');

      let textHeight;

      textHeight = textParagraph.clientHeight;

      const TweenSet = new TimelineMax();

      TweenSet
        .set(image, {
          yPercent: -101
        })
        .set(imageMask, {
          yPercent: 101
        })
        .set(imageSrc, {
          scale: 1.3
        })
        .set(textHeading, {
          y: 0
        })
        .set(textCopy, {
          y: 0
        })
        .set(textMask, {
          y: -textHeight
        })
        .set(textParagraph, {
          y: textHeight
        });

      column.addEventListener('mouseenter', () => {

        const tl = new TimelineMax();

        textHeight = textParagraph.clientHeight;

        let background = column.dataset.color

        tl
          .to(section, 1, {
            backgroundColor: background
          }, 0)
          .to(collage, 1, {
            backgroundColor: background
          }, 0)
          .to(update, 1, {
            backgroundColor: background
          }, 0)
          .to(image, 1, {
            yPercent: 0,
            ease: Power3.easeOut
          }, 0)
          .to(imageMask, 1, {
            yPercent: 0,
            ease: Power3.easeOut
          }, 0)
          .to(imageSrc, 2, {
            scale: 1,
            ease: Power3.easeOut
          }, 0)
          .to(textHeading, 1, {
            y: -textHeight / 2,
            ease: Power3.easeOut
          }, 0)
          .to(textCopy, 1, {
            y: -textHeight / 2,
            ease: Power3.easeOut
          }, 0)
          .to(textMask, 1, {
            y: 0,
            ease: Power3.easeOut
          }, 0)
          .to(textParagraph, 1, {
            y: 0,
            ease: Power3.easeOut
          }, 0);

      });

      column.addEventListener('mouseleave', () => {

        textHeight = textParagraph.clientHeight;

        const tl = new TimelineMax();

        tl
          .to(image, 1, {
            yPercent: -101,
            ease: Power3.easeOut
          }, 0)
          .to(imageMask, 1, {
            yPercent: 101,
            ease: Power3.easeOut
          }, 0)
          .to(imageSrc, 1, {
            scale: 1.3,
            ease: Power3.easeOut
          }, 0)
          .to(textHeading, 1, {
            y: 0,
            ease: Power3.easeOut
          }, 0)
          .to(textCopy, 1, {
            y: 0,
            ease: Power3.easeOut
          }, 0)
          .to(textMask, 1, {
            y: -textHeight,
            ease: Power3.easeOut
          }, 0)
          .to(textParagraph, 1, {
            y: textHeight,
            ease: Power3.easeOut
          }, 0);

      });

    });

  }

}

export default CoreValues;
