// modules
import Loaded from 'imagesloaded';
import SplitText from 'gsap/SplitText';

// components
import SmoothScroll from '../../components/scroll/scroll';

// image fullscreen
const ImageFullScreen = function () {

  const cursor = document.querySelector('.a-cursor');
  const images = document.querySelectorAll('[data-fullscreen]');

  function eventListeners() {

    [...images].forEach((image) => {

      image.addEventListener('mouseenter', () => {

        cursor.insertAdjacentHTML('beforeend', '<label>Zoom</label>');

        const label = cursor.querySelector('label');

        const splitter = new SplitText(label, {
          type: 'chars'
        }),
          chars = splitter.chars;

        TweenMax.set(chars, {
          x: 50,
          opacity: 0
        });

        TweenMax.staggerTo(chars, 1, {
          x: 0,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.05);

      });

      image.addEventListener('mouseleave', () => {
        cursor.innerHTML = '';
      });

      image.addEventListener('click', () => {

        SmoothScroll.off();

        const overlay = document.querySelector('.a-overlay');

        const width = image.offsetWidth;
        const height = image.offsetHeight;

        const offset = image.getBoundingClientRect();

        const top = offset.top;
        const left = offset.left;
        const right = offset.right;

        const bg = document.createElement('div');
        overlay.appendChild(bg);
        bg.classList.add('s-overlay-bg');

        const fullscreen = document.createElement('div');
        overlay.appendChild(fullscreen);
        fullscreen.classList.add('s-overlay-fullscreen');

        const mask = document.createElement('div');
        fullscreen.appendChild(mask);
        mask.classList.add('c-mask');

        const zoom = document.createElement('img');
        mask.appendChild(zoom);
        zoom.classList.add('c-src');

        const imagesrc = image.dataset.fullscreen;

        zoom.src = imagesrc;

        fullscreen.style.width = '' + width + 'px';
        fullscreen.style.height = '' + height + 'px';
        fullscreen.style.transform = 'translate(' + left + 'px, ' + top + 'px)';

        Loaded(zoom, function (instance) {

          // image.style.visibility = 'hidden';

          animateIn(image);

        });

      });

    });

  }

  eventListeners();

  function animateIn(image) {

    const bg = document.querySelector('.s-overlay-bg');
    const overlay = document.querySelector('.s-overlay-fullscreen');

    const width = window.innerWidth;
    const height = window.innerHeight;

    TweenMax.fromTo(bg, 1,
      { opacity: 0 },
      { opacity: 1 }
    );

    TweenMax.to(overlay, 1, {
      y: 0,
      x: 0,
      width: width,
      height: height,
      onComplete: moveEvents,
      ease: Power3.easeInOut
    });

    // setTimeout(function () {
    //   image.style.visibility = '';
    // }, 1000);

  }

  function animateOut() {

    const bg = document.querySelector('.s-overlay-bg');
    const container = document.querySelector('.s-overlay-fullscreen');
    const mask = container.querySelector('.c-mask');

    TweenMax.fromTo(bg, 1,
      { opacity: 1 },
      { opacity: 0 }
    );

    TweenMax.to(container, 1, {
      yPercent: 100,
      onComplete: destroy,
      ease: Power3.easeInOut
    });

    TweenMax.to(mask, 1, {
      yPercent: -80,
      ease: Power3.easeInOut
    });

  }

  function destroy() {

    const container = document.querySelector('.s-overlay-fullscreen');
    const bg = document.querySelector('.s-overlay-bg');

    container.remove();
    bg.remove();

    SmoothScroll.on();

  }

  function moveEvents() {

    const container = document.querySelector('.s-overlay-fullscreen');

    const mask = container.querySelector('.c-mask');
    const image = container.querySelector('.c-src');

    container.style.width = '100%';
    container.style.height = '100%';

    let posY = 0;
    let mouseY = 0;
    let difference;

    let height;
    let scroll;

    height = container.clientHeight;
    scroll = image.clientHeight;

    difference = (scroll / height) - 1;

    cursor.insertAdjacentHTML('beforeend', '<label>Close</label>');

    const label = cursor.querySelector('label');

    const splitter = new SplitText(label, {
      type: 'chars'
    }), chars = splitter.chars;

    TweenMax.set(chars, {
      x: 50,
      opacity: 0
    });

    TweenMax.staggerTo(chars, 1, {
      x: 0,
      opacity: 0.99,
      ease: Power3.easeOut
    }, 0.05);

    // resize
    function onResize() {

      height = container.clientHeight;
      scroll = image.clientHeight;

      difference = (scroll / height) - 1;
    }

    window.addEventListener('resize', onResize, false);

    // move
    // mask.addEventListener('mousemove', (e) => {

    //   mouseY = e.pageY;

    //   TweenMax.to(image, 1, {
    //     y: -mouseY * difference
    //   });

    // });

    // close
    mask.addEventListener('click', () => {

      window.removeEventListener('resize', onResize, false);

      cursor.innerHTML = '';

      mask.style.overflow = 'hidden';
      mask.style.pointerEvents = 'none';
      container.style.overflow = 'hidden';

      animateOut();

    });

  }

}

export default ImageFullScreen;
