// testimonial
class TestimonialSlider {

    constructor() {

        this.dom = {
            section: document.querySelector('.s-testimonial-slider')
        };

        if (this.dom.section) this.init();

    }

    init() {

        const slider = this.dom.section;

        const speed = 1.5;

        const easing = Power3.easeInOut;

        const nav = slider.querySelector('.c-nav');
        const navNext = slider.querySelector('.c-next');
        const navPrev = slider.querySelector('.c-prev');

        const bullets = slider.querySelectorAll('.c-bullets li');
        const texts = slider.querySelectorAll('.c-text .c-slide');
        const images = slider.querySelectorAll('.c-image .c-slide');

        const firstImage = images[0];
        const lastImage = images[images.length - 1];

        let activeText = texts[0];
        let activeImage = images[0];
        let activeBullet = bullets[0];

        let activeSrc;
        let activeMask;

        let nextSrc;
        let nextMask;
        let nextImage;

        let prevSrc;
        let prevMask;
        let prevImage;

        let prevText;
        let nextText;

        let prevBullet;
        let nextBullet;

        lastImage.classList.add('is-last');
        firstImage.classList.add('is-first');

        // set slides
        function setSlides() {

            // enable
            nav.style.pointerEvents = '';

            // classes
            activeText.classList.add('is-active');
            activeImage.classList.add('is-active');
            activeBullet.classList.add('is-active');

            // active
            activeSrc = activeImage.querySelector('.c-src');
            activeMask = activeImage.querySelector('.c-mask');

            // next
            if (activeImage.classList.contains('is-last')) {
                nextText = texts[0];
                nextImage = images[0];
                nextBullet = bullets[0];
            } else {
                nextText = activeText.nextElementSibling;
                nextImage = activeImage.nextElementSibling;
                nextBullet = activeBullet.nextElementSibling;
            }

            nextSrc = nextImage.querySelector('.c-src');
            nextMask = nextImage.querySelector('.c-mask');

            // prev
            if (activeImage.classList.contains('is-first')) {
                prevText = texts[texts.length - 1];
                prevImage = images[images.length - 1];
                prevBullet = bullets[bullets.length - 1];
            } else {
                prevText = activeText.previousElementSibling;
                prevImage = activeImage.previousElementSibling;
                prevBullet = activeBullet.previousElementSibling;
            }

            prevSrc = prevImage.querySelector('.c-src');
            prevMask = prevImage.querySelector('.c-mask');

            // set
            let tl = new TimelineMax();

            tl
                .set(images, {
                    zIndex: 1
                })
                .set(activeImage, {
                    zIndex: 2
                })
                .set(nextImage, {
                    zIndex: 3,
                    xPercent: 100
                })
                .set(nextMask, {
                    xPercent: -80
                })
                .set(nextSrc, {
                    scale: 1.5
                })
                .set(prevImage, {
                    zIndex: 3,
                    xPercent: -100
                })
                .set(prevMask, {
                    xPercent: 80
                })
                .set(prevSrc, {
                    scale: 1.5
                })
                .set(activeText, {
                    opacity: 0.99
                });

        }

        setSlides();

        // prev event
        function prevEvent() {

            prevSlide();

            activeText.classList.remove('is-active');
            activeImage.classList.remove('is-active');
            activeBullet.classList.remove('is-active');

            if (activeImage.classList.contains('is-first')) {

                activeText = texts[texts.length - 1];
                activeImage = images[images.length - 1];
                activeBullet = bullets[bullets.length - 1];

            } else {

                activeText = activeText.previousElementSibling;
                activeImage = activeImage.previousElementSibling;
                activeBullet = activeBullet.previousElementSibling;

            }

        }

        // next event
        function nextEvent() {

            nextSlide();

            activeText.classList.remove('is-active');
            activeImage.classList.remove('is-active');
            activeBullet.classList.remove('is-active');

            if (activeImage.classList.contains('is-last')) {

                activeText = texts[0];
                activeImage = images[0];
                activeBullet = bullets[0];

            } else {

                activeText = activeText.nextElementSibling;
                activeImage = activeImage.nextElementSibling;
                activeBullet = activeBullet.nextElementSibling;

            }

        }

        // click event
        navPrev.addEventListener('click', () => {
            nav.style.pointerEvents = 'none';
            prevEvent();
        });

        navNext.addEventListener('click', () => {
            nav.style.pointerEvents = 'none';
            nextEvent();
        });

        // prev slide
        function prevSlide() {

            let tl = new TimelineMax();

            tl
                .to(activeText, speed, {
                    opacity: 0,
                    ease: easing
                }, 0)
                .to(prevText, speed, {
                    opacity: 1,
                    ease: easing,
                    onComplete: setSlides
                }, 1)
                .to(activeImage, speed, {
                    xPercent: 100,
                    ease: easing
                }, 1)
                .to(activeMask, speed, {
                    xPercent: -80,
                    ease: easing
                }, 1)
                .to(prevImage, speed, {
                    xPercent: 0,
                    ease: easing
                }, 1)
                .to(prevSrc, speed, {
                    scale: 1,
                    ease: easing
                }, 1)
                .to(prevMask, speed, {
                    xPercent: 0,
                    ease: easing
                }, 1);

        }

        // next slide
        function nextSlide() {

            let tl = new TimelineMax();

            tl
                .to(activeText, speed, {
                    opacity: 0,
                    ease: easing
                }, 0)
                .to(nextText, speed, {
                    delay: 1,
                    opacity: 1,
                    ease: easing,
                    onComplete: setSlides
                }, 1)
                .to(activeImage, speed, {
                    xPercent: -100,
                    ease: easing
                }, 1)
                .to(activeMask, speed, {
                    xPercent: 80,
                    ease: easing
                }, 1)
                .to(nextImage, speed, {
                    xPercent: 0,
                    ease: easing
                }, 1)
                .to(nextSrc, speed, {
                    scale: 1,
                    ease: easing
                }, 1)
                .to(nextMask, speed, {
                    xPercent: 0,
                    ease: easing
                }, 1);

        }

    }

}

export default TestimonialSlider;
