// modules
import Sniffer from 'sniffer';
import Smooth from 'smooth-scrolling';

// settings
import HudColor from '../../js/utils/hud-color';
import ImageParallax from '../../js/utils/img-parallax';
import BackgroundColor from '../../js/utils/bg-color';

// components
import Nav from '../nav/nav';
import Logo from '../logo/logo';

// sniffer
Sniffer.addClasses(document.documentElement);

// main
let main = document.querySelector('a-main');

function scrollDesktop() {

  Nav();
  Logo();
  HudColor();
  ImageParallax();
  BackgroundColor();

}

let SmoothScroll = new Smooth({
  ease: 0.05,
  native: false,
  section: main,
  noscrollbar: true,
  callback: scrollDesktop
});

SmoothScroll.init();

if (Sniffer.isPhone) {

  SmoothScroll.destroy();

  window.addEventListener("scroll", scrollMobile);

  function scrollMobile() {

    Logo();
    HudColor();

  }

}

export default SmoothScroll;
