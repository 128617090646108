// modules
import Sniffer from 'sniffer';

// components
import SmoothScroll from '../../components/scroll/scroll';

// viewport
export function isInViewport(el, offset = 0) {

    let ScrollY;

    if (!Sniffer.isPhone) {
        scrollY = SmoothScroll.vars.current;
    }

    if (Sniffer.isPhone) {
        const body = document.documentElement;
        scrollY = (window.pageYOffset || body.scrollTop) - (body.clientTop || 0);
    }

    let top = el.offsetTop + offset;
    let height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
    }

    return (
        top < (scrollY + window.innerHeight) &&
        (top + height) > scrollY
    );

}
