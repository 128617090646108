// modules
import Loaded from 'imagesloaded';
import SplitText from 'gsap/SplitText';
import {
  lory
} from '../../js/vendors/lory.js';

// dragger
class Team {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-team')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const slider = section.querySelector('.c-members');
    const chapeau = section.querySelector('.f-chapeau');
    const member = section.querySelectorAll('.c-member');
    const heading = section.querySelector('.f-heading-xxl');
    const images = section.querySelectorAll('.s-team .c-src');

    Loaded(images, function (instance) {

      // events
      function animate() {

        let active = section.querySelector('.c-members .active');
        let image = section.querySelector('.c-members .active .c-src');
        let name = section.querySelector('.c-members .active').dataset.name;
        let role = section.querySelector('.c-members .active').dataset.function;

        function change() {

          document.querySelector('.s-team .f-chapeau').innerHTML = role;
          document.querySelector('.s-team .f-heading-xxl').innerHTML = name;

          const splitter = new SplitText(heading, {
            type: 'chars'
          }),
            chars = splitter.chars;

          TweenMax.set(chars, {
            x: 50,
            opacity: 0
          });

          TweenMax.staggerTo(chars, 1, {
            x: 0,
            opacity: 0.99,
            ease: Power3.easeOut
          }, 0.05);

          TweenMax.to(chapeau, 0.5, {
            opacity: 0.4,
            ease: Power3.easeOut
          });

          TweenMax.set(heading, {
            opacity: 1
          });

        }

        TweenMax.to(chapeau, 0.5, {
          opacity: 0,
          ease: Power3.easeOut
        });

        TweenMax.to(heading, 0.5, {
          opacity: 0,
          onComplete: change,
          ease: Power3.easeOut
        });

        TweenMax.to(member, 1, {
          opacity: 0.2,
          ease: Power3.easeOut
        });

        TweenMax.to(active, 1, {
          opacity: 0.99,
          ease: Power3.easeOut
        });

        TweenMax.to(images, 1.5, {
          scaleX: 1.2,
          scaleY: 1.2,
          ease: Power3.easeOut
        });

        TweenMax.to(image, 1.5, {
          scaleX: 1,
          scaleY: 1,
          ease: Power3.easeOut
        });

      }

      // slider
      lory(slider, {
        slideSpeed: 750,
        enableMouseEvents: true,
        classNameFrame: 'c-frame',
        classNamePrevCtrl: 'c-prev',
        classNameNextCtrl: 'c-next',
        classNameSlideContainer: 'c-slides',
        ease: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
      });

      slider.addEventListener('after.lory.slide', animate);

      // set
      let active = section.querySelector('.c-members .active');
      let image = slider.querySelector('.c-members .active .c-src');
      let name = slider.querySelector('.c-members .active').dataset.name;
      let role = slider.querySelector('.c-members .active').dataset.function;

      document.querySelector('.s-team .f-chapeau').innerHTML = role;
      document.querySelector('.s-team .f-heading-xxl').innerHTML = name;

      TweenMax.set(member, {
        opacity: 0.2,
        ease: Power3.easeOut
      });

      TweenMax.set(active, {
        opacity: 0.99,
        ease: Power3.easeOut
      });

      TweenMax.set(images, {
        scaleX: 1.2,
        scaleY: 1.2
      });

      TweenMax.set(image, {
        scaleX: 1,
        scaleY: 1
      });

    });

  }

}

export default Team;
