// modules
import Loaded from 'imagesloaded';
import SmoothScroll from '../scroll/scroll';

// viewport
import {
  isInViewport
} from '../../js/utils/is-in-viewport';

// subcategory
class SubCategory {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-subcategory')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const main = document.querySelector('.a-main');
    const top = document.querySelector('.c-top');
    const links = document.getElementsByTagName('a');
    const footer = document.querySelector('.s-footer');

    const section = this.dom.section;

    const images = section.querySelectorAll('.c-src');
    const marquee = section.querySelector('.c-marquee');

    function hideHud() {
      main.style.zIndex = "20";
      footer.style.display = "none";
    }

    hideHud();

    // images
    function initImages() {

      Loaded(images, function (instance) {

        [...images].forEach((image) => {

          const tl = new TimelineMax();

          tl
            .set(image, {
              y: 200,
              scale: 1.2,
              opacity: 0
            })

        });

        SmoothScroll.resize();

      });

    }

    initImages();

    // scroll
    let raf;

    function initScroll() {

      raf = undefined;

      let scrollY = SmoothScroll.vars.current;

      top.style.transform = 'translate3d(0px,' + scrollY + 'px,0px)';
      marquee.style.transform = 'translate3d(0px,' + scrollY + 'px,0px)';

      [...images].forEach((image) => {

        if (isInViewport(image)) {

          const tl = new TimelineMax();

          tl.to(image, 1.5, {
            y: 0,
            scale: 1,
            opacity: 0.99,
            ease: Power3.easeOut
          });

          return;

        }


      });

      start();
    }

    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(initScroll);
      }
    }

    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    start();

    // disable raf
    [...links].forEach((link) => {
      link.addEventListener('click', function () {
        stop();
      });
    }, false);

  }

}

export default SubCategory;
