// settigns
import HudColor from '../../js/utils/hud-color';

// dragger
const Burger = function () {

  const menu = document.querySelector('.a-menu');
  const burger = document.querySelector('.a-burger');

  const open = burger.querySelector('.c-open');
  const buttons = menu.querySelectorAll('li a');
  const close = burger.querySelector('.c-close');
  const opendash = open.querySelectorAll('span');
  const closedash = close.querySelectorAll('span');

  function set() {

    const tl = new TimelineMax();

    tl
      .set(closedash, {
        scaleX: 0,
        force3D: true,
      });

  }

  set();

  function enable() {
    burger.style.pointerEvents = '';
  }

  function disable() {
    burger.style.pointerEvents = 'none';
  }

  function events() {

    burger.addEventListener('click', () => {
      if (menu.classList.contains('is-visible')) {
        hide();
      } else {
        show();
      }
    });

    [...buttons].forEach((button) => {
      button.addEventListener('click', () => {
        menu.classList.remove('is-visible');
        show();
      });
    });

  }

  events();

  function hide() {

    disable();

    const tl = new TimelineMax();

    burger.classList.add('is-white');

    tl
      .staggerTo(opendash, 0.5, {
        scaleX: 0,
        force3D: true,
        ease: Expo.easeOut
      }, 0.1)
      .staggerTo(closedash, 0.5, {
        scaleX: 1,
        force3D: true,
        ease: Expo.easeOut
      }, 0.1, 0.5, enable);
  }

  function show() {

    disable();

    HudColor();

    const tl = new TimelineMax();

    burger.classList.remove('is-white');

    tl
      .staggerTo(closedash, 0.5, {
        scaleX: 0,
        force3D: true,
        ease: Expo.easeOut
      }, 0.1)
      .staggerTo(opendash, 0.5, {
        scaleX: 1,
        force3D: true,
        ease: Expo.easeOut
      }, 0.1, 0.5, enable);
  }

}

export default Burger;
