import Loaded from 'imagesloaded';
import SmoothScroll from '../scroll/scroll';

// how we work
class HowWeWork {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-how-we-work')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const windowHeight = window.innerHeight;

    const nav = section.querySelector('.c-nav');
    const images = section.querySelectorAll('.c-src');
    const chapter = section.querySelector('.c-chapter');
    const buttons = section.querySelectorAll('.f-link');
    const categories = section.querySelectorAll('.c-category');

    let sectionHeight;

    // images
    function initImages() {

      Loaded(images, function (instance) {

        [...images].forEach((image) => {

          let imageParent = image.parentNode;

          if (image.naturalHeight > image.naturalWidth) {
            imageParent.classList.add('is-portrait')
          } else {
            imageParent.classList.add('is-landscape')
          }

          sectionHeight = section.clientHeight;

          SmoothScroll.resize();

        });

        initEvents();

      });

    }

    initImages();

    // nav
    function initNav() {

      let navOffset = nav.offsetTop;
      let navHeight = nav.clientHeight;

      let chapterOffset = chapter.offsetTop;
      let chapterHeight = chapter.clientHeight;

      let sectionOffset = section.offsetTop;

      let navSet = (windowHeight / 2) - (navHeight / 2) - navOffset;

      nav.style.transform = 'translate3d(0px,' + navSet + 'px,0px)';

      // viewport
      let isInViewport = function (el) {

        let rect = el.getBoundingClientRect();

        return rect.bottom > 0 &&
          rect.right > 0 &&
          rect.top < (window.innerHeight || document.documentElement.clientHeight);
      };

      // scroll
      let raf;

      function initScroll() {

        raf = undefined;

        sectionHeight = section.clientHeight;

        let scrollY = SmoothScroll.vars.current;

        // sticky nav
        let stickyCalc = (windowHeight / 2) - (navHeight / 2) - navOffset;
        let stickyHeight = (sectionHeight + sectionOffset) - windowHeight;

        if ((scrollY > sectionOffset) && (scrollY < stickyHeight)) {

          let offsetNav = scrollY - (sectionOffset - stickyCalc);
          let offsetChapter = scrollY - (sectionOffset - stickyCalc);

          nav.style.transform = 'translate3d(0px,' + offsetNav + 'px,0px)';
          chapter.style.transform = 'translate3d(0px,' + offsetChapter + 'px,0px)';

        }

        if (scrollY <= sectionOffset) { }

        // active nav
        [...categories].forEach((category) => {

          const id = category.dataset.category;
          const active = section.querySelector('[data-id=' + id + ']');

          if (isInViewport(category)) {

            category.classList.add('is-inview');

            [...buttons].forEach((button) => {
              button.classList.remove('is-active');
            });

            if (!active.classList.contains('is-active')) {
              active.classList.add('is-active');
            }

          } else {
            category.classList.remove('is-inview');
          }

        });

        // images
        [...images].forEach((image) => {

          let offset = image.getBoundingClientRect().top + scrollY;
          let height = image.clientHeight;

          let distance = (scrollY + windowHeight) - offset;
          let percentage = distance / ((windowHeight + height) / 100);

          if (percentage < 100) {

            TweenMax.to(image, 0.2, {
              yPercent: percentage / 4
            });

          }

          return percentage;

        });

        startScroll();
      }

      // start
      function startScroll() {
        if (!raf) {
          raf = window.requestAnimationFrame(initScroll);
        }
      }

      startScroll();

      // stop
      function stopScroll() {
        if (raf) {
          window.cancelAnimationFrame(raf);
          raf = undefined;
        }
      }

      // disable
      const links = document.getElementsByTagName('a');

      [...links].forEach((link) => {
        link.addEventListener('click', () => {
          stopScroll();
        });
      }, false);

    }

    Loaded(images, function (instance) {
      setTimeout(function () {
        initNav();
      }, 1000);
    });

    // events
    function initEvents() {

      [...buttons].forEach((button) => {
        button.addEventListener('click', () => {

          let id = button.dataset.id;

          let category = section.querySelector('[data-category=' + id + ']');
          let categoryHeight = category.clientHeight;

          let heading = category.querySelector('.f-heading-xl');

          function offset(el) {

            let rect = el.getBoundingClientRect(),
              scrollY = SmoothScroll.vars.current;
            return {
              top: rect.top + scrollY,
            }
          }

          let headingOffset = offset(heading);
          let offsetY = headingOffset.top - (windowHeight / 2);

          SmoothScroll.scrollTo(offsetY)

        });
      });

    }

  }

}

export default HowWeWork;
