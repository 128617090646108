// modules
import Sniffer from 'sniffer';

// collage
class Collage {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-collage')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const images = section.querySelectorAll('.c-image');
    const chapeau = section.querySelector('.f-chapeau');
    const heading = section.querySelector('.f-heading-xxl');

    const shape = section.querySelector('.c-shape');
    const left = section.querySelector('.c-hit-left');
    const right = section.querySelector('.c-hit-right');

    const colorleft = left.dataset.color;
    const colorright = right.dataset.color;

    const corevalues = document.querySelector('.s-core-values');
    const update = document.querySelector('.s-update');

    function tweenLeft() {

      const tl = new TimelineMax();

      tl
        .to(images[0].querySelectorAll('.c-src')[0], 0.2, {
          opacity: 1
        }, 0)
        .to(images[0].querySelectorAll('.c-src')[1], 0.2, {
          opacity: 0
        }, 0)
        .to(images[2].querySelectorAll('.c-src')[0], 0.2, {
          opacity: 1
        }, 0)
        .to(images[2].querySelectorAll('.c-src')[1], 0.2, {
          opacity: 0
        }, 0)
        .to(images[3].querySelectorAll('.c-src')[0], 0.2, {
          opacity: 1
        }, 0)
        .to(images[3].querySelectorAll('.c-src')[1], 0.2, {
          opacity: 0
        }, 0)
        .to(images[5].querySelectorAll('.c-src')[0], 0.2, {
          opacity: 1
        }, 0)
        .to(images[5].querySelectorAll('.c-src')[1], 0.2, {
          opacity: 0
        }, 0)
        .to(section, 0.75, {
          backgroundColor: colorleft
        }, 0)
        .to(update, 0.75, {
          backgroundColor: colorleft
        }, 0)
        .to(corevalues, 0.75, {
          backgroundColor: colorleft
        }, 0);

    }

    function tweenRight() {

      const tl = new TimelineMax();

      tl
        .to(images[0].querySelectorAll('.c-src')[0], 0.2, {
          opacity: 0
        }, 0)
        .to(images[0].querySelectorAll('.c-src')[1], 0.2, {
          opacity: 1
        }, 0)
        .to(images[2].querySelectorAll('.c-src')[0], 0.2, {
          opacity: 0
        }, 0)
        .to(images[2].querySelectorAll('.c-src')[1], 0.2, {
          opacity: 1
        }, 0)
        .to(images[3].querySelectorAll('.c-src')[0], 0.2, {
          opacity: 0
        }, 0)
        .to(images[3].querySelectorAll('.c-src')[1], 0.2, {
          opacity: 1
        }, 0)
        .to(images[5].querySelectorAll('.c-src')[0], 0.2, {
          opacity: 0
        }, 0)
        .to(images[5].querySelectorAll('.c-src')[1], 0.2, {
          opacity: 1
        }, 0)
        .to(section, 0.75, {
          backgroundColor: colorright
        }, 0)
        .to(update, 0.75, {
          backgroundColor: colorright
        }, 0)
        .to(corevalues, 0.75, {
          backgroundColor: colorright
        }, 0);

    }

    // background
    left.addEventListener('mouseenter', function () {
      tweenLeft();
    });

    right.addEventListener('mouseenter', function () {
      tweenRight();
    });

    tweenLeft();

    // split
    const headingSplit = new SplitText(heading, {
      type: 'words, chars'
    }), headingChars = headingSplit.chars;

    const chapeauSplit = new SplitText(chapeau, {
      type: 'words, chars'
    }), chapeauChars = chapeauSplit.chars;

    // set
    TweenMax.set(headingChars, {
      y: 25,
      opacity: 0
    });

    TweenMax.set(chapeauChars, {
      x: 10,
      opacity: 0
    });

    // tween
    TweenMax.staggerTo(headingChars, 1, {
      y: 0,
      delay: 1,
      opacity: 0.99,
      ease: Power3.easeOut
    }, 0.05);

    TweenMax.staggerTo(chapeauChars, 1, {
      x: 0,
      delay: 1,
      opacity: 0.99,
      ease: Power3.easeOut
    }, 0.05);

    // set
    const left1 = images[0];
    const left2 = images[1];
    const left3 = images[2];

    const right1 = images[3];
    const right2 = images[4];
    const right3 = images[5];

    let spY = window.innerHeight / 2;
    let spX = window.innerWidth / 2;

    TweenMax.set(shape, {
      y: spY / 20,
      x: spX / 20,
      transformOrigin: 'center',
      transformPerspective: 1500
    });

    TweenMax.set(left1, {
      y: -spY / 20,
      x: spX / 20,
      transformOrigin: 'center',
      transformPerspective: 1500
    });

    TweenMax.set(left2, {
      y: -spY / 15,
      x: spX / 15,
      transformOrigin: 'center',
      transformPerspective: 1000
    });

    TweenMax.set(left3, {
      y: -spY / 10,
      x: spX / 10,
      transformOrigin: 'center',
      transformPerspective: 500
    });

    TweenMax.set(right1, {
      y: spY / 20,
      x: spX / 20,
      transformOrigin: 'center',
      transformPerspective: 1500
    });

    TweenMax.set(right2, {
      y: spY / 10,
      x: spX / 10,
      transformOrigin: 'center',
      transformPerspective: 500
    });

    TweenMax.set(right3, {
      y: spY / 15,
      x: spX / 15,
      transformOrigin: 'center',
      transformPerspective: 1000
    });

    // move
    section.addEventListener('mousemove', function (e) {

      let x = (e.clientX / window.innerWidth) - 0.5;
      let y = (e.clientY / window.innerHeight) - 0.5;

      let vpY = e.clientY;
      let vpX = e.clientX;

      TweenMax.to(shape, 1, {
        y: vpY / 20,
        x: vpX / 20,
        ease: Power1.easeOut
      });

      // default
      if (!Sniffer.isSafari) {

        TweenMax.to(left1, 1, {
          y: -vpY / 20,
          x: vpX / 20,
          rotationY: 25 * x,
          rotationX: 25 * y,
          ease: Power1.easeOut
        });

        TweenMax.to(left2, 1, {
          y: -vpY / 15,
          x: vpX / 15,
          rotationY: 25 * x,
          rotationX: 25 * y,
          ease: Power1.easeOut
        });

        TweenMax.to(left3, 1, {
          y: -vpY / 10,
          x: vpX / 10,
          rotationY: 25 * x,
          rotationX: 25 * y,
          ease: Power1.easeOut
        });

        TweenMax.to(right1, 1, {
          y: vpY / 20,
          x: vpX / 20,
          rotationY: 25 * x,
          rotationX: 25 * y,
          ease: Power1.easeOut
        });

        TweenMax.to(right2, 1, {
          y: vpY / 10,
          x: vpX / 10,
          rotationY: 25 * x,
          rotationX: 25 * y,
          ease: Power1.easeOut
        });

        TweenMax.to(right3, 1, {
          y: vpY / 15,
          x: vpX / 15,
          rotationY: 25 * x,
          rotationX: 25 * y,
          ease: Power1.easeOut
        });

      }

      // safari
      if (Sniffer.isSafari) {

        TweenMax.to(left1, 1, {
          y: -vpY / 20,
          x: vpX / 20,
          ease: Power1.easeOut
        });

        TweenMax.to(left2, 1, {
          y: -vpY / 15,
          x: vpX / 15,
          ease: Power1.easeOut
        });

        TweenMax.to(left3, 1, {
          y: -vpY / 10,
          x: vpX / 10,
          ease: Power1.easeOut
        });

        TweenMax.to(right1, 1, {
          y: vpY / 20,
          x: vpX / 20,
          ease: Power1.easeOut
        });

        TweenMax.to(right2, 1, {
          y: vpY / 10,
          x: vpX / 10,
          ease: Power1.easeOut
        });

        TweenMax.to(right3, 1, {
          y: vpY / 15,
          x: vpX / 15,
          ease: Power1.easeOut
        });

      }

    });

  }

}

export default Collage;
