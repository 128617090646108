// modules
import Sniffer from 'sniffer';
import Loaded from 'imagesloaded';
import SmoothScroll from '../scroll/scroll';

// category
class Category {

  constructor() {

    this.dom = {
      section: document.querySelector('.s-category')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const windowHeight = window.innerHeight;

    const cursor = document.querySelector('.a-cursor');

    const images = section.querySelectorAll('.c-image');
    const categories = section.querySelectorAll('.c-category');

    // categories
    [...categories].forEach((category) => {

      let src = category.querySelector('.c-src');

      category.addEventListener('mouseenter', () => {

        TweenMax.to(src, 1, {
          scaleX: 1.05,
          scaleY: 1.05,
          ease: Power3.easeOut
        });

      });

      category.addEventListener('mouseleave', () => {

        TweenMax.to(src, 1, {
          scaleX: 1,
          scaleY: 1,
          ease: Power3.easeOut
        });

      });

    });

    // images
    Loaded(images, function (instance) {

      [...images].forEach((image) => {

        const src = image.querySelector('.c-src');

        const parentLink = src.parentNode;
        const parentImage = parentLink.parentNode;
        const parentPost = parentImage.parentNode;

        if (src.naturalHeight > src.naturalWidth) {
          parentPost.classList.add("is-portrait")
        } else {
          parentPost.classList.add("is-landscape")
        }

        image.addEventListener('mouseenter', () => {

          cursor.insertAdjacentHTML('beforeend', '<label>View</label>');

          const label = cursor.querySelector('label');

          const splitter = new SplitText(label, {
            type: 'chars'
          }), chars = splitter.chars;

          TweenMax.set(chars, {
            x: 50,
            opacity: 0
          });

          TweenMax.staggerTo(chars, 1, {
            x: 0,
            opacity: 0.99,
            ease: Power3.easeOut
          }, 0.05);

        });

        image.addEventListener('mouseleave', () => {
          cursor.innerHTML = '';
        });

      });

      if (!Sniffer.isPhone) {
        SmoothScroll.resize();
      }

      Scroll();

    });

    // scroll
    function Scroll() {

      let raf;

      // init
      function initScroll() {

        raf = undefined;

        [...categories].forEach((category) => {

          const src = category.querySelector('.c-src');

          let scrollY;

          if (!Sniffer.isPhone) {
            scrollY = SmoothScroll.vars.current;
          }

          if (Sniffer.isPhone) {
            const body = document.documentElement;
            scrollY = (window.pageYOffset || body.scrollY) - (body.clientTop || 0);
          }

          let offset = category.getBoundingClientRect().top + scrollY;
          let height = category.clientHeight;

          let distance = (scrollY + windowHeight) - offset;
          let percentage = distance / ((windowHeight + height) / 100);

          if (percentage < 100) {

            TweenMax.to(src, 0.2, {
              yPercent: percentage / 4
            });

          }

          return percentage;

        });

        start();
      }

      // start
      function start() {
        if (!raf) {
          raf = window.requestAnimationFrame(initScroll);
        }
      }

      start();

      // stop
      function stop() {
        if (raf) {
          window.cancelAnimationFrame(raf);
          raf = undefined;
        }
      }

      // disable
      const links = document.getElementsByTagName('a');

      [...links].forEach((link) => {
        link.addEventListener('click', function (e) {
          stop();
        });
      }, false);

    }

  }

}

export default Category;
