import SmoothScroll from '../scroll/scroll';

// image zoom
const Nav = function () {

  let scrollY = SmoothScroll.vars.current;

  const nav = document.querySelector('.a-nav');
  const burger = document.querySelector('.a-burger');

  const buttons = nav.querySelectorAll('li');

  const tl = new TimelineMax();

  if (scrollY > 100) {

    if (!nav.classList.contains('is-hidden')) {

      nav.style.pointerEvents = 'none';

      tl
        .to(burger, 1, {
          x: '5.5vw',
          ease: Power3.easeOut
        }, 0)
        .staggerTo(buttons, 0.5, {
          y: 5,
          opacity: 0
        }, 0.1, 0);
    }

    nav.classList.add('is-hidden')

  } else if (scrollY <= 100) {

    nav.style.pointerEvents = 'all';

    if (nav.classList.contains('is-hidden')) {

      tl
        .to(burger, 1, {
          x: '0'
        }, 0)
        .staggerTo(buttons, 0.5, {
          y: 0,
          opacity: 0.99,
          clearProps: 'all',
          ease: Power3.easeOut
        }, 0.1, 0);

      nav.classList.remove('is-hidden')

    }

  }

}

export default Nav;
