// modules
import Loaded from 'imagesloaded';

// marquee
class Marquee {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-marquee')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;

    const svg = section.getElementsByTagName('svg')[0];

    const mask = svg.querySelector('.c-mask');
    const image = svg.querySelector('.c-image');

    // ratio
    function ratio() {

      let width = window.innerWidth;
      let height = window.innerHeight;

      svg.setAttribute("viewBox", "0 0 " + width + " " + height + "");
      svg.setAttribute("height", "" + height + "px");
      svg.setAttribute("width", "" + width + "px");

      image.setAttribute("height", "" + height + "px");
      image.setAttribute("width", "" + width + "px");

      // mask.style.width = "" + width + "px";
      mask.style.height = "" + height + "px";

    }

    // resize
    window.onresize = function () {
      ratio();
    };

    // set
    ratio();

    // marquee
    Loaded(section, function (instance) {

      const tl = new TimelineMax();

      TweenMax.to(mask, 20, {
        repeat: -1,
        xPercent: -50,
        ease: Power0.easeOut
      }, 0);

    });

  }

}

export default Marquee;
