// modules
import SplitText from 'gsap/SplitText';

// components
import SmoothScroll from '../scroll/scroll';

// magazines
class Magazines {
  constructor() {

    this.dom = {
      section: document.querySelector('.s-magazines')
    };

    if (this.dom.section) this.init();
  }

  init() {

    const section = this.dom.section;
    const windowHeight = window.innerHeight;

    const nav = document.querySelector('.a-nav');
    const logo = document.querySelector('.a-logo');
    const cursor = document.querySelector('.a-cursor');
    const burger = document.querySelector('.a-burger');
    const footer = document.querySelector('.s-footer');
    const background = document.querySelector('.a-background');

    footer.style.display = 'none';
    background.style.backgroundColor = '#FFFFFF';

    const heading = section.querySelector('.c-heading');
    const magazine = section.querySelectorAll('.c-magazine');

    const hoverHeading = section.querySelector('.c-in');
    const defaultHeading = section.querySelector('.c-out');

    let hoverChars;
    let hoverSplitter;

    let defaultSplitter = new SplitText(defaultHeading, {
      type: "words,chars"
    }), defaultChars = defaultSplitter.chars;

    // change
    function change(color, title) {

      hoverHeading.innerHTML = '';
      hoverHeading.innerHTML = title;

      hoverSplitter = new SplitText(hoverHeading, {
        type: 'words,chars'
      }), hoverChars = hoverSplitter.chars;

      TweenMax.set(hoverChars, {
        y: 50,
        opacity: 0,
        onComplete: animate
      });

      function animate() {

        TweenMax.killTweensOf(hoverChars);
        TweenMax.killTweensOf(defaultChars);

        TweenMax.to(heading, 0.5, {
          css: {
            color: '#ffffff'
          }
        }, 0);

        TweenMax.to(background, 0.5, {
          backgroundColor: color
        }, 0);

        TweenMax.staggerTo(defaultChars, 1, {
          y: -50,
          opacity: 0,
          ease: Power3.easeOut
        }, 0.02);

        TweenMax.staggerTo(hoverChars, 1, {
          y: 0,
          delay: 0.2,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.02);
      }

    }

    // reset
    function reset() {

      TweenMax.killTweensOf(hoverChars);
      TweenMax.killTweensOf(defaultChars);

      TweenMax.set(defaultChars, {
        y: 50,
        opacity: 0
      });

      TweenMax.to(heading, 0.5, {
        css: {
          color: '#000000'
        }
      });

      TweenMax.staggerTo(defaultChars, 1, {
        y: 0,
        delay: 0.2,
        opacity: 0.99,
      }, 0.02);

      TweenMax.staggerTo(hoverChars, 1, {
        y: -50,
        opacity: 0,
        ease: Power3.easeOut
      }, 0.02);

      TweenMax.to(magazine, 0.5, {
        opacity: 0.99
      }, 0);

      TweenMax.to(background, 0.5, {
        backgroundColor: '#FFFFFF'
      }, 0);

    }

    // events
    [...magazine].forEach((cover) => {

      cover.addEventListener('mouseenter', () => {

        let color = cover.dataset.color;
        let title = cover.innerText || cover.textContent;

        change(color, title);

        nav.classList.add('is-white');
        logo.classList.add('is-white');
        burger.classList.add('is-white');

        cursor.insertAdjacentHTML('beforeend', '<label>View</label>');

        const label = cursor.querySelector('label');

        const labelSplitter = new SplitText(label, {
          type: 'chars'
        }), labelChars = labelSplitter.chars;

        TweenMax.set(labelChars, {
          x: 50,
          opacity: 0
        });

        TweenMax.staggerTo(labelChars, 1, {
          x: 0,
          opacity: 0.99,
          ease: Power3.easeOut
        }, 0.05);

        TweenMax.to(magazine, 0.5, {
          opacity: 0.2
        }, 0);

        TweenMax.to(cover, 0.5, {
          opacity: 0.99
        }, 0);

      });

      cover.addEventListener('mouseleave', () => {

        reset();

        cursor.innerHTML = '';

        nav.classList.remove('is-white');
        logo.classList.remove('is-white');
        burger.classList.remove('is-white');

      });

    }, false);

    // scroll
    let raf;

    function scroll() {

      raf = undefined;

      let scrollY = SmoothScroll.vars.current;

      heading.style.transform = 'translateY(' + scrollY + 'px)';

      [...magazine].forEach((cover) => {

        let rand = cover.dataset.speed;

        let offset = cover.getBoundingClientRect().top + scrollY;
        let height = cover.clientHeight;

        let distance = (scrollY + windowHeight) - offset;
        let percentage = distance / ((windowHeight + height) / 100);

        if (percentage < 100) {

          TweenMax.to(cover, 0.2, {
            yPercent: percentage / rand
          });

        }

        return percentage;

      });

      start();
    }

    function start() {
      if (!raf) {
        raf = window.requestAnimationFrame(scroll);
      }
    }

    start();

    function stop() {
      if (raf) {
        window.cancelAnimationFrame(raf);
        raf = undefined;
      }
    }

    // destroy raf
    const links = document.getElementsByTagName('a');

    [...links].forEach((link) => {
      link.addEventListener('click', () => {
        stop();
      });
    }, false);

  }

}

export default Magazines;
